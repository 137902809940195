import * as React from "react";

const IconMenu = ({ icon_classes }) => {
  return (
    <svg className={icon_classes} enableBackground="new 0 0 17 12" height="12" viewBox="0 0 17 12" width="17" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">
      <path d="m17 10.66669v1h-17v-1z"/>
      <path d="m17 5.5v1h-17v-1z"/>
      <path d="m17 .33331v1h-17v-1z"/>
    </svg>
  )
}

export default IconMenu

