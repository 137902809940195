import { navigate } from "gatsby";
import { useTheme } from '~/contexts/ThemeContext';
import QlReq from '~/utils/ql-req'
import * as Queries from '~/helpers/ql-queries'

const useShopCustomerHook = () => {

  const { customer, setCustomer } = useTheme()

  const login = async ( email, pass, noredirect=false ) => {

    const token_obj = await get_token(email, pass)

    if ( !token_obj ) return false

    const cust_obj = await get_custInfo(token_obj.accessToken)
    cust_obj['token'] = token_obj.accessToken
    cust_obj['expires'] = token_obj.expiresAt

    setCustomer(cust_obj)
    localStorage.setItem('cu', JSON.stringify(cust_obj))
    if (!localStorage.getItem('ga_register')) localStorage.setItem('ga_login','true') 
    if (!noredirect) navigate('/account')
    return true
  }
  
  const get_token = async ( email, pass ) => {
    const vars = {
      input: {
        email: email,
        password: pass
      }
    }
    const query = Queries.q_login()
    const req = await QlReq.post(query, vars)

    if ( req.customerAccessTokenCreate.customerUserErrors.length > 0) {
      handle_error()
      return false
    }

    const token = req.customerAccessTokenCreate.customerAccessToken
    return token
  }


  const mp_auth = async ( multipass ) => {

    const token_obj = await mp_token(multipass)

    if ( !token_obj ) return false

    const cust_obj = await get_custInfo(token_obj.accessToken)
    cust_obj['token'] = token_obj.accessToken
    cust_obj['expires'] = token_obj.expiresAt

    setCustomer(cust_obj)
    localStorage.setItem('cu', JSON.stringify(cust_obj))
    if (!localStorage.getItem('ga_register')) localStorage.setItem('ga_login','true') 
    navigate('/account')
    return true
  }

  const mp_token = async ( multipass ) => {
    const vars = {
      multipassToken: multipass
    }
    const query = Queries.q_mp_auth()
    const req = await QlReq.post(query, vars)

    if ( req.customerAccessTokenCreateWithMultipass.customerUserErrors.length > 0) {
      handle_error()
      return false
    }

    const token = req.customerAccessTokenCreateWithMultipass.customerAccessToken
    return token
  }
  

  const get_custInfo = async ( token ) => {
    const vars = {
      customerAccessToken: token
    }
    const query = Queries.q_customerId()
    const req = await QlReq.post(query, vars)

    if ( !req.customer ) return {}

    req.customer['totalAmount'] = 0
    if ( req.customer.orders.edges.length > 0 ) {
      req.customer.totalAmount = req.customer.orders.edges.reduce( 
        (total, x) => total + parseFloat(x.node.subtotalPrice.amount), 0 
      )
    }
    
    delete req.customer.orders
    return req.customer
  }

  const update_customer = async () => {
    const new_cust = await get_custInfo(customer.token)

    new_cust['token'] = customer.token
    new_cust['expires'] = customer.expires

    setCustomer(new_cust)
    localStorage.setItem('cu', JSON.stringify(new_cust))
  }


  const handle_error = () => {
    console.log('there was a problem signing you in')
  }


  const sign_out = () => {
    localStorage.removeItem('cu')
    setCustomer(null)
    navigate('/')
  }


  const recover = async ( email ) => {
    const vars = {
      email: email
    }
    const query = Queries.q_recover()
    const req = await QlReq.post(query, vars)

    if (req.customerRecover.customerUserErrors.length < 1) {
      return true
    }

    return false
  }


  const register = async ( vars, noredirect=false ) => {
    const query = Queries.q_register()
    const req = await QlReq.post(query, vars)

    if ( req.customerCreate.customerUserErrors.length > 0 ) {
      handle_error()
      return 
    }

    localStorage.setItem('ga_register','true')
    const token = await login(vars.input.email, vars.input.password, noredirect)
    
    if (token.customerCreate.customerUserErrors.length > 0) console.log('Problem logging in')

    return true
  }


  const reset = async ( pass, url ) => {
    const vars = {
      password: pass,
      resetUrl: url
    }
    const query = Queries.q_reset()
    const req = await QlReq.post(query, vars)

    if ( req.customerResetByUrl.customerUserErrors.length > 0 ) {
      handle_error()
      return 
    }

    const new_cust = req.customerResetByUrl.customer

    new_cust['token'] = req.customerResetByUrl.customerAccessToken.accessToken
    new_cust['expires'] = req.customerResetByUrl.customerAccessToken.expiresAt

    new_cust['totalAmount'] = 0
    if ( new_cust.orders.edges.length > 0 ) {
      new_cust.totalAmount = new_cust.orders.edges.reduce( 
        (total, x) => total + parseFloat(x.node.subtotalPrice.amount), 0 
      )
    }

    delete new_cust.orders
    setCustomer(new_cust)
    localStorage.setItem('cu', JSON.stringify(new_cust))
    navigate('/account')

    return true
  }



  const check_refresh = async () => {
    delete customer.check_expire
    const now = Date.now()
    const expires = Date.parse(customer.expires)
    
    if ( (now < (expires - 864000000)) || (now > expires) ) {
      setCustomer(customer)
      localStorage.setItem('cu', JSON.stringify(customer))
      return
    }
    
    const vars = {
      customerAccessToken: customer.token
    }
    const query = Queries.q_renew()
    const req = await QlReq.post(query, vars)
    if ( req.customerAccessTokenRenew.userErrors.length > 0) {
      handle_error()
      return false
    }

    const token = req.customerAccessTokenRenew.customerAccessToken.accessToken
    const expires_at = req.customerAccessTokenRenew.customerAccessToken.expiresAt
    customer['token'] = token
    customer['expires'] = expires_at

    setCustomer(customer)
    localStorage.setItem('cu', JSON.stringify(customer))
  }

  const order_history = async () => {
    const vars = {
      customerAccessToken: customer.token
    }
    const query = Queries.q_orders()
    const req = await QlReq.post(query, vars)

    if ( !req.customer || !req.customer.orders ) return []

    const orders = req.customer.orders.edges.map(x => x.node)
    orders.forEach( order => {
      order.lineItems.edges.forEach( line => {
        if (!line.node.variant || !line.node.variant.product.images) return
        let img = line.node.variant.product.images.edges.find(x => x.node.altText && x.node.altText.toLowerCase().includes('product flat'))
        if (!img) img = line.node.variant.product.images.edges[0]
        line.node.variant.product['image'] = img.node
      })
    })

    return orders
  }

  const addy_delete = async ( id ) => {
    const vars = {
      customerAccessToken: customer.token,
      id: id
    }
    const query = Queries.q_addy_del()
    const req = await QlReq.post(query, vars)

    if (req.customerUserErrors.length > 0) console.log('Problem deleting address')

    update_customer()
  }

  const addy_update = async ( id, obj, isDefault=false ) => {
    const vars = {
      customerAccessToken: customer.token,
      id: id,
      address: obj
    }

    const query = Queries.q_addy_upd()
    const req = await QlReq.post(query, vars)

    if (req.customerUserErrors.length > 0) console.log('Problem updating address')

    if (!isDefault) update_customer()
  }

  const addy_new = async (obj, isDefault=false ) => {
    const vars = {
      customerAccessToken: customer.token,
      address: obj
    }

    const query = Queries.q_addy_new()
    const req = await QlReq.post(query, vars)

    if (req.customerUserErrors.length > 0) console.log('Problem adding address')

    if (isDefault) {
      const set_default = await addy_default(req.customerAddressCreate.customerAddress.id)
      if (!set_default) console.log('Problem setting as default')
    }

    update_customer()
  }

  const addy_default = async ( id ) => {
    const vars = {
      customerAccessToken: customer.token,
      addressId: id
    }

    const query = Queries.q_addy_def()
    const req = await QlReq.post(query, vars)

    if (req.customerUserErrors.length > 0) console.log('Problem setting as default')

    update_customer()
    return true
  }

  const addy_countries = async () => {
    
    const query = Queries.q_addy_countries()
    const req = await QlReq.post(query, {})

    return req
  }


  return { 
    login, mp_auth, sign_out, check_refresh, recover, reset, register, 
    order_history, addy_delete, addy_update, addy_new, addy_default,
    addy_countries
  }

};

export default useShopCustomerHook;
