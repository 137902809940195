import React, { useState, useEffect } from "react";
import { Link } from 'gatsby'
import { useTheme } from '~/contexts/ThemeContext';
import { notBlank } from '~/helpers/helpers'

import { IconChevRight } from '~/components/icons/chevrons'

import { aria_btn, cta_link } from "~/styles/app.module.scss"
import * as styles from "~/styles/stores/store_panel.module.scss"


const PanelRegion = ({ regionData, selectStore, showOpen }) => {
  const { locate } = useTheme()

  const [open, setOpen] = useState(showOpen)
  let inStock = regionData.stores

  const check_feats = (store) => {
    return !store.placeholder && (store.avail_mens || store.avail_womens)
  }

  const check_details = (store) => {
    return !store.placeholder && (store.hours || store.address_line_1 || check_feats(store))
  }

  const handleClick = () => {
    setOpen( !open )
  }
  
  useEffect(() => {
    setOpen(showOpen)
  }, [showOpen]);


  return (
    <div className={styles.region} >
      <div className={styles.region_head}>
        <div className={styles.title}>
          <button 
            className={`${aria_btn} ${styles.title_btn}`}
            aria-label="Toggle this region's stores" 
            onClick={(e)=> {handleClick()}} 
          >
            {regionData.region}
          </button>
        </div>
        <div className={styles.region_hide}>
          <button 
            className={`${aria_btn} ${styles.hide_btn} ${open ? styles.collapse : '' }`}
            aria-label="Toggle this region's stores" 
            onClick={(e)=> {handleClick()}} 
          >
            <IconChevRight icon_classes={''} />
          </button>
        </div>
      </div>

      <div className={styles.stores} aria-expanded={open ? 'true' : 'false'} aria-hidden={open ? 'false' : 'true'}>
        {inStock.map((store, index) => 

          <div key={`storeside-${index}`} className={styles.store} data-shortname={store.short_name}>
            
            <div className={styles.infos} aria-hidden='false'>

              <div className={styles.info}>
                <div className={styles.title}>
                  {(store.placeholder || !notBlank(store.page)) ? (
                    <span dangerouslySetInnerHTML={{ __html: store.title }} />
                  ) : (
                    <Link to={`/pages/${store.page}`}>{store.title}</Link>
                  )}
                </div>

                <div className={styles.dist}>
                  {(store.hasOwnProperty('distance') && store.distance) ? `${store.distance}mi` : ''}
                </div>

                {!store.placeholder && (
                  <div>
                    <button 
                      className={[aria_btn, cta_link].join(' ')} 
                      onClick={(e)=>{selectStore(store)}}
                    >
                      {!locate?.currStores.includes(store.short_name) ? 'Shop Store' : 'Clear'}
                    </button>
                  </div>
                )}
              </div>
              
            </div>

            {check_details(store) && (
              <div className={styles.details}>
                {store.address_line_1 && (
                  <div className={styles.detail}>
                    <div>
                      <p>
                        {store.address_line_1 && (
                          <>
                            {store.address_line_1}
                            <br/>
                          </>
                        )}
                        {store.address_line_2 && (
                          <>
                            {store.address_line_2}
                            <br/>
                          </>
                        )}
                        {store.address_city && `${store.address_city}, ${store.address_state} ${store.address_zip}`}
                      </p>
                      {store.phone && (
                        <>
                          <a href={`tel:{store.phone.replaceAll('.', '').replaceAll('-', '')`}>
                            {store.phone}
                          </a>
                          <br/>
                        </>
                      )}
                    </div>

                    {(!store.placeholder && store.gmaps) && (
                      <div className={styles.gdirs}>
                        <a href={`https://www.google.com/maps/dir/?api=1&destination=${store.gmaps}`} target="_blank" rel="noreferrer">
                          Map
                        </a>
                      </div>
                    )}
                  </div>
                )}
                {store.hours && (
                  <div className={styles.detail}>
                    {notBlank(store.hours) && store.hours.split('\n').map( (time,index) => 
                      <React.Fragment key={`${store.short_name}}time${index}`}>
                        {time}
                        <br/>
                      </React.Fragment>
                    )}
                  </div>
                )}
                {check_feats(store) && (
                  <div className={styles.features}>
                    <div>
                      At this store:
                    </div>

                    {store.avail_mens && (
                      <div>
                        Men's
                      </div>
                    )}
                    {store.avail_womens && (
                      <div>
                        Women's
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

          </div>
        )}
      </div>
    </div>


  )
}

export default PanelRegion

