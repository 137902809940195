import { useLocation } from '@reach/router'

const useTestFunnelHook = () => {
  const location = useLocation()

  let tests = []

  // {
  //   id: '100498409',
  //   location: '/products/',
  //   el: null,
  //   ev_cart: '100464621',
  //   ev_check: '100465178',
  // }
  // {
  //   id: '1004102378',
  //   location: '/collections/womens',
  //   el: null,
  //   ev_cart: '100466796',
  //   ev_check: '100466797',
  // }
  // {
  //   id: '100498409',
  //   location: '/products/',
  //   el: null,
  //   ev_cart: '100464621',
  //   ev_check: '100465178',
  // }

  const show_logs = () => {
    return !!localStorage.getItem('fnnl-logs')
  }

  const fnnl_page = (direct_el=false) => {
    let curr = tests.find(x => location.pathname.includes(x.location))
    if (direct_el) {
      curr = tests.find(x => x.el === direct_el)
    }

    let el_clicked = true
    if (curr && curr.el) {
      let els = localStorage.getItem(`fnnl-els`) ? localStorage.getItem(`fnnl-els`).split(',') : []
      el_clicked = els.includes(curr.el)
    }

    if (curr) {
      if (el_clicked) localStorage.setItem(`fnnl`,curr.id)
      if (show_logs()) console.log('fnnl', curr)
      return
    }

    let is_active = localStorage.getItem(`fnnl`)

    if (!is_active) return

    if (show_logs()) console.log('fnnl is_active', is_active)

    is_active = tests.find(x => x.id === is_active)
    if (!location.pathname.includes('/products/') || location.pathname.includes(is_active.location)) {
      fnnl_clear()
    }
  }

  const fnnl_set_els = (name) => {
    let els = localStorage.getItem(`fnnl-els`) ? localStorage.getItem(`fnnl-els`).split(',') : []
    els = [...new Set(els.concat([name]))]
    localStorage.setItem(`fnnl-els`,els.join(','))
  }

  const fnnl_cart = (newcart, var_id, qty=1, opts={}) => {
    const ispickup = opts?.pickup || false
    const isTest = (opts?.isTest && opts?.isTest !== 'change') || false
    const isChange = opts?.isTest === 'change' || false

    const fnnl_exist = localStorage.getItem(`fnnl`)
    const fnnl_cart_exist = localStorage.getItem(`fnnl-cart`)

    if (!(fnnl_exist || fnnl_cart_exist)) return

    let cart = JSON.parse(localStorage.getItem(`fnnl-cart`)) || {}

    let test_id
    if (fnnl_exist) {
      test_id = fnnl_exist
    } else {
      test_id = get_test_id(cart, var_id)
    }
    if (!test_id) return


    let test_obj = cart.hasOwnProperty(test_id) && cart[test_id]
    if (!test_obj) {
      test_obj = {
        id: test_id,
        products: []
      }
    }

    const var_str = var_id.toString().replace(`gid://shopify/ProductVariant/`,'')
    let test_variant = test_obj.products.find(x => x.var_id === var_str)

    // REMOVE AFTER TEST
    if (!isTest && !(isChange && test_variant)) return

    if (test_variant) {
      test_variant.qty += qty
    } else {
      test_variant = {
        var_id: var_str,
        qty: 1,
        price: get_price(newcart, var_str)
      }
    }

    test_obj.products = test_obj.products.filter(x => x.var_id !== var_str)
    if (qty !== 0 && test_variant.qty !== 0) {

      test_obj.products.push(test_variant)
      
      const test_info = tests.find(x => x.id === test_id)
      if (!test_info) return

      const cartid = (ispickup && test_info.ev_cart === '100464621') ? '100464622' : test_info.ev_cart

      window._conv_q = window._conv_q || []
      window._conv_q.push(["pushRevenue", test_variant.price, 1, cartid, "force_multiple"])
    }

    if (test_obj.products.length > 0) {
      cart[test_id] = test_obj
    } else {
      delete cart[test_id]
    }

    if (Object.keys(cart).length < 1) {
      localStorage.removeItem(`fnnl-cart`)
      localStorage.removeItem(`fnnl-check`)

      if ( get_cookie('_fnl_ck') ) {
        const is_local = window.location.hostname.includes('localhost')
        document.cookie = `_fnlch=;path=/;${is_local ? '' : ' domain=buckmason.com;'}expires=Thu, 01 Jan 1970 00:00:01 GMT`
      }

    }
    localStorage.setItem(`fnnl-last`,test_id)
    localStorage.setItem(`fnnl-cart`,JSON.stringify(cart))

    if (show_logs()) {
      console.log(`fnnl-last`,test_id)
      console.log(`fnnl-cart`, cart)
    }
  }

  const get_test_id = (cart, var_id) => {
    const keys = Object.keys(cart)
    let test_id = false
    keys.forEach((id) => {
      const test_obj = cart[id]
      if (test_obj.products.find(x => x.var_id === var_id)) test_id = id
    })
    if (!test_id && localStorage.getItem(`fnnl-last`)) {
      test_id = localStorage.getItem(`fnnl-last`)
    }
    return test_id
  }

  const get_price = (newcart, var_str) => {
    const shop_var_id = `gid://shopify/ProductVariant/${var_str}`
    const line = newcart.lines.edges.find(x => x.node.merchandise.id === shop_var_id)
    if (!line) return 0
    const price = parseFloat(line.node.merchandise.priceV2.amount)
    return price
  }

  const fnnl_clear = () => {
    localStorage.removeItem(`fnnl`)
    if (show_logs()) console.log('fnnl_clear')
  }

  const fnnl_check = () => {
    let cart = JSON.parse(localStorage.getItem(`fnnl-cart`))
    if (!cart) {
      localStorage.removeItem(`fnnl-check`)
      return
    }

    const keys = Object.keys(cart)
    let events = keys.map((id) => {
      const test_info = tests.find(x => x.id === id)
      if (!test_info) return {qty: 0}

      const cart_item = cart[id]
      const lines_qty = cart_item.products.map(x => x.qty).reduce((total, x) => total + x, 0)
      const lines_rev = cart_item.products.map(x => x.qty * x.price).reduce((total, x) => total + x, 0)

      const obj = {
        ev_id: test_info.ev_check,
        rev: lines_rev,
        qty: lines_qty
      }

      return obj
    })
    
    events = events.filter(x => x.qty > 0)

    if (events.length < 1) return 
      
    const cookie_arr = events.map(x => {
      return {e: x.ev_id, r: x.rev, q: x.qty}
    })
    set_cookie('_fnlch', JSON.stringify(cookie_arr))
  }

  const set_cookie = (name, data, days=1) => {
    if (typeof document === 'undefined') return null
    const is_local = window.location.hostname.includes('localhost')
    let date = new Date()
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
    let expires = `; expires=${date.toUTCString()}`
    let cookie_dom = is_local ? '' : ' domain=buckmason.com'
    const cookie_str = `${name}=${data}${expires}; path=/;${cookie_dom}`
    document.cookie = cookie_str
  }

  const get_cookie = (name) => {
    if (typeof document === 'undefined') return null
    const nameEQ = `${name}=`
    var ca = document.cookie.split(';')
    for(var i=0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1,c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length)
    }
    return null
  }


  const fnnl_tile = () => {
    let curr = tests.find(x => location.pathname.includes(x.location))
    let is_active = localStorage.getItem(`fnnl`)
    if (!is_active && !curr) return
    if (show_logs()) console.log(`fnnl-tile`)

    is_active = tests.find(x => x.id === is_active)
    if (!location.pathname.includes(is_active.location)) return

    window._conv_q = window._conv_q || []
    window._conv_q.push(["triggerConversion", "100449410"])
  }

  return { fnnl_page, fnnl_cart, fnnl_tile, fnnl_clear, fnnl_check, fnnl_set_els }
}

export default useTestFunnelHook


// {
//   id: '100493918',
//   location: '/collections/mens-henleys',
//   el: null,
//   ev_cart: '100461916',
//   ev_check: '100461917',
// },
// {
//   id: '100493918',
//   location: '/collections/mens-shirts',
//   el: null,
//   ev_cart: '100461916',
//   ev_check: '100461917',
// },
// {
//   id: '100493918',
//   location: '/collections/mens-accessories',
//   el: null,
//   ev_cart: '100461916',
//   ev_check: '100461917',
// },
// {
//   id: '100493918',
//   location: '/collections/mens-jeans',
//   el: null,
//   ev_cart: '100461916',
//   ev_check: '100461917',
// },
// {
//   id: '100493918',
//   location: '/collections/mens-pants',
//   el: null,
//   ev_cart: '100461916',
//   ev_check: '100461917',
// },
// {
//   id: '100493918',
//   location: '/collections/mens-shorts',
//   el: null,
//   ev_cart: '100461916',
//   ev_check: '100461917',
// },
// {
//   id: '100493918',
//   location: '/collections/womens-tanks',
//   el: null,
//   ev_cart: '100461916',
//   ev_check: '100461917',
// },
// {
//   id: '100493918',
//   location: '/collections/womens-tees',
//   el: null,
//   ev_cart: '100461916',
//   ev_check: '100461917',
// },
// {
//   id: '100493918',
//   location: '/collections/womens-accessories',
//   el: null,
//   ev_cart: '100461916',
//   ev_check: '100461917',
// }
// {
//   id: '100476397',
//   location: '/collections/mens-tees',
//   el: null,
//   ev_cart: '100449706',
//   ev_check: '100449720',
// },
// let tests = [
//   {
//     id: '100471927',
//     location: '/collections/tees',
//     el: null,
//     ev_cart: '100449706',
//     ev_check: '100449720',
//   },
//   {
//     id: '55555',
//     location: '/collections/new-arrivals',
//     el: null,
//     ev_cart: '666666',
//     ev_check: '77777',
//   }
// ]




// import * as React from "react"
// import { useLocation } from '@reach/router'

// const useTestFunnelHook = () => {
//   const location = useLocation()

//   let tests = [
//     {
//       id: '100487594',
//       location: null,
//       el: 'quick',
//       sess_test: false,
//       ev_cart: '100460664',
//       ev_check: '100460665',
//     },
//     {
//       id: '5555555',
//       location: '/collections/mens-tees',
//       el: null,
//       sess_test: false,
//       ev_cart: '6666666',
//       ev_check: '77777',
//     },
//   ]

//   // {
//   //   id: '100476397',
//   //   location: '/collections/mens-tees',
//   //   el: null,
//   //   ev_cart: '100449706',
//   //   ev_check: '100449720',
//   // },
//   // let tests = [
//   //   {
//   //     id: '100471927',
//   //     location: '/collections/tees',
//   //     el: null,
//   //     ev_cart: '100449706',
//   //     ev_check: '100449720',
//   //   },
//   //   {
//   //     id: '55555',
//   //     location: '/collections/new-arrivals',
//   //     el: null,
//   //     ev_cart: '666666',
//   //     ev_check: '77777',
//   //   }
//   // ]

//   const show_logs = () => {
//     return !!localStorage.getItem('fnnl-logs')
//   }

//   const set_arr = (type, name) => {
//     let arr = localStorage.getItem(type)?.split(',') || []
//     if (!arr.includes(name)) {
//       arr = [...new Set( arr.concat([name]) )]
//       localStorage.setItem(type,arr.join(','))
//     }
//   }

//   const rem_arr = (type, name) => {
//     let arr = localStorage.getItem(type)?.split(',') || []
//     arr = arr.filter(x => x !== name)
//     arr.length < 1 ? localStorage.removeItem(type) : localStorage.setItem(type, arr.join(','))
//   }

//   const fnnl_page = (direct_el=false) => {
//     let curr = tests.find(x => location.pathname.includes(x.location))
//     if (direct_el) {
//       curr = tests.find(x => x.el === direct_el)
//     }

//     if (curr) {
//       set_arr(`fnnl`, curr.id)
//       if (show_logs()) console.log('fnnl', curr)
//       return
//     }

//     let is_actives = localStorage.getItem(`fnnl`)

//     if (!is_actives) return

//     is_actives = is_actives.split(',')
//     is_actives = tests.filter(x => is_actives.includes(x.id))

//     if (show_logs()) console.log('fnnl is_active', is_actives)

//     const active_locs = is_actives.filter(x => !!x.location).map(x => x.location)
//     if (!location.pathname.includes('/products/') || active_locs.includes(location.pathname)) {
//       fnnl_clear(is_actives)
//     }
//   }

//   const fnnl_cart = (newcart, var_id, qty=1) => {
//     const fnnl_exist = localStorage.getItem(`fnnl`)
//     const fnnl_cart_exist = localStorage.getItem(`fnnl-cart`)

//     if (!(fnnl_exist || fnnl_cart_exist)) return

//     let cart = JSON.parse(localStorage.getItem(`fnnl-cart`)) || {}

//     let test_id = []
//     if (fnnl_exist) {
//       test_id = fnnl_exist
//     } else {
//       test_id = get_test_id(cart, var_id)
//     }

//     console.log('fnnl_cart', test_id, fnnl_cart_exist)

//     if (!test_id) return


//     let test_obj = cart.hasOwnProperty(test_id) && cart[test_id]
//     if (!test_obj) {
//       test_obj = {
//         id: test_id,
//         products: []
//       }
//     }

//     const var_str = var_id.toString().replace(`gid://shopify/ProductVariant/`,'')
//     let test_variant = test_obj.products.find(x => x.var_id === var_str)

//     if (test_variant) {
//       test_variant.qty += qty
//     } else {
//       test_variant = {
//         var_id: var_str,
//         qty: 1,
//         price: get_price(newcart, var_str)
//       }
//     }

//     test_obj.products = test_obj.products.filter(x => x.var_id !== var_str)
//     if (qty !== 0 && test_variant.qty !== 0) {
//       test_obj.products.push(test_variant)
      
//       const test_info = tests.find(x => x.id === test_id)
//       if (!test_info) return
//       window._conv_q = window._conv_q || []
//       window._conv_q.push(["pushRevenue", test_variant.price, 1, test_info.ev_cart, "force_multiple"])
//     }

//     if (test_obj.products.length > 0) {
//       cart[test_id] = test_obj
//     } else {
//       delete cart[test_id]
//     }

//     if (Object.keys(cart).length < 1) {
//       localStorage.removeItem(`fnnl-cart`)
//       localStorage.removeItem(`fnnl-check`)

//       if ( get_cookie('_fnl_ck') ) {
//         const is_local = window.location.hostname.includes('localhost')
//         document.cookie = `_fnlch=;path=/;${is_local ? '' : ' domain=buckmason.com;'}expires=Thu, 01 Jan 1970 00:00:01 GMT`
//       }

//     }
//     localStorage.setItem(`fnnl-last`,test_id)
//     localStorage.setItem(`fnnl-cart`,JSON.stringify(cart))

//     if (show_logs()) {
//       console.log(`fnnl-last`,test_id)
//       console.log(`fnnl-cart`, cart)
//     }
//   }

//   const get_test_id = (cart, var_id) => {
//     // {
//     //   "5555555": {
//     //     "id": "5555555",
//     //     "products": [
//     //       {
//     //         "var_id": "41919100223571",
//     //         "qty": 1,
//     //         "price": 55
//     //       }
//     //     ]
//     //   }
//     // }
    
//     const keys = Object.keys(cart)
//     let test_id = false
//     keys.forEach((id) => {
//       const test_obj = cart[id]
//       if (test_obj.products.find(x => x.var_id === var_id)) test_id = id
//     })
//     if (!test_id && localStorage.getItem(`fnnl-last`)) {
//       test_id = localStorage.getItem(`fnnl-last`)
//     }
//     return test_id
//   }

//   const get_price = (newcart, var_str) => {
//     const shop_var_id = `gid://shopify/ProductVariant/${var_str}`
//     const line = newcart.lines.edges.find(x => x.node.merchandise.id === shop_var_id)
//     if (!line) return 0
//     const price = parseFloat(line.node.merchandise.priceV2.amount)
//     return price
//   }

//   const fnnl_clear = (actives) => {
//     actives.forEach(active => {
//       if (active.sess_test) return
//       if (active.location && location.pathname !== active.location) rem_arr(`fnnl`, active.id)
//     })
//     if (show_logs()) console.log('fnnl_clear')
//   }

//   const fnnl_check = () => {
//     let cart = JSON.parse(localStorage.getItem(`fnnl-cart`))
//     if (!cart) {
//       localStorage.removeItem(`fnnl-check`)
//       return
//     }

//     const keys = Object.keys(cart)
//     let events = keys.map((id) => {
//       const test_info = tests.find(x => x.id === id)
//       if (!test_info) return {qty: 0}

//       const cart_item = cart[id]
//       const lines_qty = cart_item.products.map(x => x.qty).reduce((total, x) => total + x, 0)
//       const lines_rev = cart_item.products.map(x => x.qty * x.price).reduce((total, x) => total + x, 0)

//       const obj = {
//         ev_id: test_info.ev_check,
//         rev: lines_rev,
//         qty: lines_qty
//       }

//       return obj
//     })
    
//     events = events.filter(x => x.qty > 0)

//     if (events.length < 1) return 
      
//     const cookie_arr = events.map(x => {
//       return {e: x.ev_id, r: x.rev, q: x.qty}
//     })
//     set_cookie('_fnlch', JSON.stringify(cookie_arr))
//   }

//   const set_cookie = (name, data, days=1) => {
//     if (typeof document === 'undefined') return null
//     const is_local = window.location.hostname.includes('localhost')
//     let date = new Date()
//     date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
//     let expires = `; expires=${date.toUTCString()}`
//     let cookie_dom = is_local ? '' : ' domain=buckmason.com'
//     const cookie_str = `${name}=${data}${expires}; path=/;${cookie_dom}`
//     document.cookie = cookie_str
//   }

//   const get_cookie = (name) => {
//     if (typeof document === 'undefined') return null
//     const nameEQ = `${name}=`
//     var ca = document.cookie.split(';')
//     for(var i=0; i < ca.length; i++) {
//       let c = ca[i];
//       while (c.charAt(0) === ' ') c = c.substring(1,c.length)
//       if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length)
//     }
//     return null
//   }


//   const fnnl_tile = () => {
//     let curr = tests.find(x => location.pathname.includes(x.location))
//     let is_active = localStorage.getItem(`fnnl`)
//     if (!is_active && !curr) return
//     if (show_logs()) console.log(`fnnl-tile`)

//     is_active = tests.find(x => x.id === is_active)
//     if (!location.pathname.includes(is_active.location)) return

//     window._conv_q = window._conv_q || []
//     window._conv_q.push(["triggerConversion", "100449410"])
//   }

//   return { fnnl_page, fnnl_cart, fnnl_tile, fnnl_clear, fnnl_check }
// }

// export default useTestFunnelHook

