import React, { useState } from 'react';
import { notBlank } from '~/helpers/helpers'
import Modal from '~/components/shared/modal'

import { aria_btn } from "~/styles/app.module.scss"
import * as mstyles from "~/styles/shared/modal.module.scss"
import * as styles from "~/styles/pdp/sizing.module.scss"



const Sizing = ({ guide, onClose }) => {
  const { sizing_table_garment, sizing_copy_garment, sizing_image_garment, 
          sizing_table_body, sizing_copy_body, sizing_image_body, measurement_copy_body, 
          measurement_copy_garment, guide_help_copy 
        } = guide

  const [curr, setCurr] = useState('dimensions')

  return (
    <Modal name={'Sizing'} childStyles={''} onModalClose={onClose}>

      <div className={mstyles.title} id="armodal_lab-sizing">
        Size Chart
      </div>

      <div className={styles.head}>
        {(notBlank(sizing_table_garment) && notBlank(sizing_table_body)) && (
          <button 
            className={aria_btn} 
            id="sizing_garment" 
            aria-expanded={curr === 'dimensions' ? 'true' : 'false'}
            aria-controls="ar_sizing_garment"
            onClick={(e) => setCurr('dimensions')}
          >
            Product Dimensions
          </button>
        )}
        {(notBlank(sizing_image_garment) && notBlank(sizing_image_body)) && (
          <button 
            className={aria_btn}  
            id="sizing_guide" 
            aria-expanded={curr === 'guide' ? 'true' : 'false'}
            aria-controls="ar_sizing_guide" 
            onClick={(e) => setCurr('guide')}
          >
            Measuring Guide
          </button>
        )}
      </div>

      <div>
        {(notBlank(sizing_table_garment) || notBlank(sizing_table_body)) && (
          <div 
            className={styles.panel} 
            id="ar_sizing_garment" 
            role="region" 
            aria-labelledby="sizing_garment"  
            aria-hidden={curr === 'dimensions' ? 'false' : 'true'}
          >
            {notBlank(sizing_table_garment) && (
              <>
                <div 
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: sizing_copy_garment || measurement_copy_garment}} 
                />
                <div 
                  className={styles.table} 
                  dangerouslySetInnerHTML={{ __html: sizing_table_garment }} 
                /> 
              </>
            )}
            
            {notBlank(sizing_table_body) && (
              <>
                <div 
                  className={styles.description} 
                  dangerouslySetInnerHTML={{ __html: sizing_copy_body || measurement_copy_body}} 
                />
                <div 
                  className={styles.table} 
                  dangerouslySetInnerHTML={{ __html: sizing_table_body }} 
                /> 
              </>
            )}

            <div className={styles.description}>
              <p dangerouslySetInnerHTML={{ __html: guide_help_copy }} /> 
            </div>
          </div>
        )}

        {(sizing_image_garment || sizing_image_body) && (
          <div 
            className={styles.panel} 
            id="ar_sizing_guide" 
            role="region" 
            aria-labelledby="sizing_guide"  
            aria-hidden={curr === 'guide' ? 'false' : 'true'}
          >
            <div className={styles.description}>
              In order to best determine fit, it may be helpful to compare our garment’s measurements to a similar garment you already own. 
              Lay your garment on a flat surface and take all measurements from the outside of the garment.
            </div> 
            <img src={sizing_image_garment} className={styles.diagram} alt="" />

            {sizing_image_body && (
              <>
                <br />
                <br />
                <img src={sizing_image_body} className={styles.diagram} alt="" />
              </>
            )}
          </div>
        )}
      </div>

    </Modal>
  )
};

export default Sizing



// {guide.sizing_table_body && (
//   <>
//     <div 
//       className={styles.sizing_description}
//       dangerouslySetInnerHTML={{ __html: guide.sizing_copy_body || guide.measurement_copy_body }} 
//     />
//     <div 
//       className={styles.sizing_tablewr}
//       dangerouslySetInnerHTML={{ __html: guide.sizing_table_body }} 
//     /> 
//   </>
// )}
// {guide.sizing_image_body && (
//   <img src={guide.sizing_image_body} className={styles.sizing_diagram} alt=""/>
// )}
// {guide.sizing_table_garment  && (
//   <>
//     <div 
//       className={styles.sizing_description}
//       dangerouslySetInnerHTML={{ __html: guide.sizing_copy_garment || guide.measurement_copy_garment}} 
//     />
//     <div 
//       className={styles.sizing_tablewr}
//       dangerouslySetInnerHTML={{ __html: guide.sizing_table_garment }}
//     /> 
//   </>
// )}
// {guide.sizing_image_garment  && (
//   <img src={guide.sizing_image_garment} className={styles.sizing_diagram} alt=""/>
// )}
// <div className={styles.accordion_section}>
//   {guide.body_help1_title  && (
//     <>
//       <button 
//         className={[aria_btn, styles.accordion].join(' ')}
//         id="accordian_size1" 
//         aria-expanded="false" 
//         aria-controls="accordian_size1-panel"
//       >
//         Body Measuring Guide
//       </button>
    
//     </>
//   )}
//   {guide.help1_title  && (
//     <>
//       <button 
//         className={[aria_btn, styles.accordion].join(' ')}
//         id="accordian_size2" 
//         aria-expanded="false" 
//         aria-controls="accordian_size2-panel"
//       >
//         Garment Measuring Guide
//       </button>
     
//     </>
//   )}
//   <div className={[styles.accordion_panel, styles.always_open].join(' ')}>
//     <div className={styles.accordion_item} dangerouslySetInnerHTML={{ __html: guide.guide_help_copy }} /> 
//   </div>
// </div>




