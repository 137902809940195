// extracted by mini-css-extract-plugin
export var blind = "search-module--blind--de6eb";
export var btn = "search-module--btn--cef14";
export var close = "search-module--close--20364";
export var colors = "search-module--colors--47f73";
export var count = "search-module--count--7fa9b";
export var foot = "search-module--foot--cbf70";
export var gender_btn = "search-module--gender_btn--0b884";
export var head = "search-module--head--437e0";
export var iconSearch = "search-module--icon-search--ecd2b";
export var image = "search-module--image--806ef";
export var img = "search-module--img--25c2f";
export var info = "search-module--info--4ea83";
export var inputwr = "search-module--inputwr--b16b0";
export var item = "search-module--item--ac405";
export var item_title = "search-module--item_title--3406d";
export var page_image = "search-module--page_image--9f1fc";
export var popular = "search-module--popular--992f4";
export var popular_item = "search-module--popular_item--f5b57";
export var popular_list = "search-module--popular_list--8dc20";
export var price = "search-module--price--41864";
export var restitle = "search-module--restitle--d23cc";
export var results = "search-module--results--21cc5";
export var resultswr = "search-module--resultswr--aabfa";
export var search = "search-module--search--19409";
export var search_btn = "search-module--search_btn--6753b";
export var search_gender = "search-module--search_gender--d1565";
export var search_inpt = "search-module--search_inpt--a11dc";
export var search_resultTitle = "search-module--search_result-title--d5990";
export var search_resultsBack_btn = "search-module--search_results-back_btn--ec0d4";
export var title = "search-module--title--22fe2";