import { useStaticQuery, graphql } from "gatsby"

export const useSearchContentQuery = () => {

  const query = useStaticQuery(
    graphql`
      {
        contentPage(handle: {eq: "search-panel"}) {
          data
        }
      }
    `
  )


  return query.contentPage.data
}


