import * as React from "react";

const IconCart = ({ icon_classes }) => {
  return (
    <svg 
      className={icon_classes} 
      x="0px" 
      y="0px" 
      viewBox="0 0 126 126" 
      style={{enableBackground: 'new 0 0 126 126'}} 
      aria-hidden="true" 
      focusable="false" 
      role="presentation" 
    >
      <path d="M99.6,123.2H26.4c-4.2,0-7.7-3.8-7.7-8.4V33.4c0-4.9,3.7-8.9,8.1-8.9h72.8c4.1,0,7.6,3.7,7.6,8.3v82C107.2,119.4,103.8,123.2,99.6,123.2z"/>
      <path d="M40,24.6c3-10.7,12.2-20.7,23-20.7c10.8,0,19.9,9.9,23,20.6"/>
    </svg>
  )
}

export default IconCart

