import React, { useState } from "react";
import useAnalyticsHook from '~/utils/analytics'

import { aria_btn } from "~/styles/app.module.scss"
import * as styles from "~/styles/shared/news_email.module.scss"


const KlaviyoNews = ({ listId, formId, place, bg='#fff', footer=false }) => {
  const { ga_signup_news, elev_subscribe, li_signup_news } = useAnalyticsHook()

  const [success, setSuccess] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault()

    const formels = e.target.elements
    const obj = {}
    Array.from(formels).map(x => [x.name, x.value]).filter(x => x[0] !== '' && x[0] !== 'default').forEach(x => {
      obj[x[0]] = x[1]
    })

    if (obj.email === '' || !(obj.email.includes('@') &&obj.email.includes('.'))) return

    const list = process.env.NODE_ENV === "development" ? 'SVBt4a' : listId
    const form = process.env.NODE_ENV === "development" ? 'TyaQ82' : formId

    const data = {
      data: {
        type: 'subscription',
        attributes: {
          profile: {
            data: {
              type: 'profile',
              attributes: {
                email: obj.email,
                properties: {
                  "$consent_form_id": form
                }
              }
            }
          }
        },
        relationships: {
          list: {
            data: {
              type: 'list',
              id: list
            }
          }
        }
      }
    }

    fetch('https://a.klaviyo.com/client/subscriptions/?company_id=Kd4snB', {
      method: "POST",
      body: JSON.stringify(data),
      data: data,
      headers: {
        "Content-Type": "application/json",
        "Revision": "2024-07-15"
      }
    })
    .then(async(response) => {
      setSuccess(response.ok)
      if (response.ok) {
        elev_subscribe(obj.email)
        ga_signup_news()
        li_signup_news(obj.email)

        if (footer) {
          window._conv_q = window._conv_q || []
          window._conv_q.push(["triggerConversion", "100451704"])
        }
      }
    }) 
  }

  return (
    <div className={styles.wrap}>
      {footer && (
        <label htmlFor="email">Sign Up for Complimentary Shipping and the Latest News</label>
      )}
      <div className={styles.form}>
        <form onSubmit={handleSubmit}>
          {footer && (
            <label htmlFor="email" style={{display: 'none'}}>Your Email</label>
          )}
          <input 
            className={styles.inpt} 
            type="email"
            id="email" 
            name={'email'}
            required="required" 
            autoCapitalize="none"
            autoCorrect="off" 
            spellCheck="false" 
            autoComplete="email" 
            autocompletetype="email" 
            x-autocompletetype="email" 
            placeholder={place} 
          />
          <button 
            type="submit" 
            aria-label="Sign up" 
            className={[aria_btn, styles.btn].join(' ')}
          ></button>
        </form>
        {success && (
          <div className={styles.msg} style={{backgroundColor: `${bg}`}}>
            {success ? (
              <p>
                Thank you for signing up
              </p>
            ) : (
              <p>
                There was a problem submitting. Please refresh try again.
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default KlaviyoNews


// vintage: VSvZQQ
// "$consent_form_id": "TyaQ82"


// footer: T77fAT
// "$consent_form_id": "XkrxZb"

// test: SVBt4a
// "$consent_form_id": "TyaQ82"




