import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { useTheme } from '~/contexts/ThemeContext';
import { useFooterListsQuery } from "~/hooks/useFooterListsQuery"
import KlaviyoNews from '~/components/shared/klaviyo-news'
import { IconChevLeft } from '~/components/icons/chevrons'

import { aria_btn } from "~/styles/app.module.scss"
import * as styles from "~/styles/footer.module.scss"


const Footer = () => {
  const { analytics, setAnalytics, isMobile } = useTheme()
  const footq = useFooterListsQuery()
  const lists = footq.lists
  const store = footq.store

  const [didLoad, setDidLoad] = useState(false)
  const [privOpen, setPrivOpen] = useState(false)
  const [mobOpen, setMobOpen] = useState(null)

  const checkClick = (name) => {
    if (name.includes('Gift Cards')) {
      setAnalytics({...analytics, list: 'footer menu'})
    }
  }

  const mobileClick = (index) => {
    if (mobOpen === index) {
      setMobOpen(null)
      return
    }
    setMobOpen(index)
  }

  useEffect(() => {
    setDidLoad(true)
  }, [])

  return (
    <footer className={styles.foot}>

      {(didLoad && isMobile) && (
        <div className={styles.contact} footemail={''}>
          <KlaviyoNews listId={'T77fAT'} formId={'XkrxZb'} place={'Email'} footer={true} />
        </div>
      )}

      <nav className={styles.cont}>
        {store && (
          <div className={styles.storewr}>
            <h4 className={styles.title}>
              Come Visit Us
            </h4>
            <Link to={`/pages/${store.page}/`} className={styles.image}>
              <img className={styles.img} src={store.image.replace('.jpg','_400x.jpg')} height={200} width={200} alt="" />
            </Link>

            <div className={styles.store_name}>
              <p>
                <Link to={'/pages/our-stores/'}>
                  <span>Find a Store</span>
                </Link>
              </p>
            </div>
          </div>
        )}

        {lists.map( (list, listIndex) => 
          <div key={`footlist_${listIndex}`} className={styles.links}>
            {(didLoad && isMobile) && (
              <button
                id={`arfootlstbtn-${listIndex}`}
                className={[aria_btn, styles.acc_btn].join(' ')}
                aria-controls={!isMobile ? `arfootlst-${listIndex}` : undefined}
                aria-expanded={listIndex === mobOpen ? 'true' : 'false'}
                onClick={(e)=> mobileClick(listIndex)}
              >
                <h4 className={styles.title}>
                  {list.title}
                </h4>
                <IconChevLeft />
              </button>
            )}
            <ul
              id={`arfootlst-${listIndex}`}
              aria-hidden={listIndex === mobOpen ? 'false' : 'true'}
              aria-labelledby={`arfootlstbtn-${listIndex}`}
            >
              {(didLoad && !isMobile) && (
                <li>
                  <h4 className={styles.title}>
                    {list.title}
                  </h4>
                </li>
              )}
              {list.links.map( (link, linkIndex) => {
                const is_ext = link.url.includes('https:')
                return (
                  <li key={`footlink${listIndex}_${linkIndex}`}>
                    {is_ext ? (
                      <a 
                        href={link.url} 
                        target={'_blank'} 
                        rel={'noreferrer'}
                      >
                        {link.copy}
                      </a>
                    ) : (
                      <Link 
                        to={link.url} 
                        onClick={(e) => checkClick(link.copy) }
                      >
                        {link.copy}
                      </Link>
                    )}
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </nav>

      {(didLoad && !isMobile) && (
        <div className={styles.contact} footemail={''}>
          <KlaviyoNews listId={'T77fAT'} formId={'XkrxZb'} place={'Email'} footer={true} />
        </div>
      )}

      <div className={[styles.cont, styles.corp_legal].join(' ')}>
        <div className={styles.legal_wr}>
          <button
            id={'arfootbtn-legal'}
            className={[aria_btn, styles.acc_btn].join(' ')}
            aria-controls={'arfoot-legal'}
            aria-expanded={privOpen ? 'true' : 'false'}
            onClick={(e)=> setPrivOpen(!privOpen)}
          >
            Privacy
            <IconChevLeft />
          </button>
          <ul 
            id={'arfoot-legal'}
            className={styles.legal} 
            aria-hidden={privOpen ? 'false' : 'true'}
            aria-labelledby={'arfootbtn-legal'}
          >
            <li>
              <Link to="/pages/privacy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/pages/accessibility">Accessibility</Link>
            </li>
            <li>
              <Link to="/pages/customer-data-request-form">Customer Data Request Form</Link>
            </li>
            <li className={styles.legal}>
              {didLoad ? (
                <button id="ot-sdk-btn" className={`${styles.aria_btn} aria_btn ot-sdk-show-settings`}>
                  Do Not Sell or Share My Personal Information
                </button>
              ) : (
                <button className={`${styles.aria_btn} aria_btn`}>
                  Do Not Sell or Share My Personal Information
                </button>
              )}
            </li>
            <li>
              <Link to="/pages/terms">Terms of Service</Link>
            </li>
          </ul>
        </div>

        <div className={styles.corp}>
          © BUCK MASON. All Rights Reserved
          <br/>
          2013 - {new Date().getFullYear()}
        </div>
      </div>

    </footer>
  )
}

export default Footer



