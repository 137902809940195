import { useStaticQuery, graphql } from "gatsby"

export const useHeaderQuery = () => {
  const { headers, journals } = useStaticQuery(
    graphql`
      query {
        headers: contentPage(type: {eq: "SiteHeader"}) {
          data
        }
        journals: allContentPage(filter: {type: {eq: "HeadJournals"}}) {
          nodes {
            handle
            type
            data
          }
        }
      }
    `
  )

  return {headers: headers.data, journals: journals.nodes}
}