import * as React from "react";

const IconClose = ({ icon_classes }) => {
  return (
    <svg 
      className={icon_classes} 
      enableBackground="new 0 0 13 14" 
      height="14" 
      viewBox="0 0 13 14" 
      width="13" 
      xmlns="http://www.w3.org/2000/svg" 
      aria-hidden="true" 
      focusable="false"
    >
      <path style={{fill: 'none', stroke: 'currentColor', strokeMiterlimit: '10'}} d="M0.5,1l12,12"/>
      <path style={{fill: 'none', stroke: 'currentColor', strokeMiterlimit: '10'}}  d="M12.5,1l-12,12"/>
    </svg>
  )
}

export default IconClose

