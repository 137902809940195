import React, { useState, useEffect } from "react"
import { useTheme } from '~/contexts/ThemeContext'
import { useProduct } from '~/contexts/ProductContext'
import { jnstyls } from '~/helpers/helpers'

import IconClose from '~/components/icons/close'
// import PdpEdd from '~/components/pdp/pdpEdd'
import PickupStores from '~/components/pdp/pickup-stores'

import { aria_btn, bubb_btn, dotcheck_label, dotcheck } from "~/styles/app.module.scss"
import * as styles from "~/styles/pdp/pickup.module.scss"


const ShipPickup = ({ bis=false }) => {
  const { pdpVariant, locate, setLocate } = useTheme()
  const { product, blockPickup, isPickup, onSwitchDelivery, anyStores, nearInstock } = useProduct()
  const { is_gift } = product

  const [allowPickup, setAllowPickup] = useState(false)

  const checkPickup = () => {
    setAllowPickup(pdpVariant.shop_avail && !!pdpVariant?.pickup && !blockPickup && anyStores?.length > 0)
  }

  const handleToggle = (type) => {
    onSwitchDelivery(type)
    if (type === 'pickup') {
      window._conv_q = window._conv_q || []
      // Goal: 100464616 = Pickup: Pickup toggled
      window._conv_q.push(["triggerConversion", "100464616"])
    }
  }

  const setIntl = (state) => {
    setLocate({
      ...locate,
      shipIntl: state
    })
  }

  useEffect(() => {
    if (is_gift) {
      setAllowPickup(false)
      return
    }
    checkPickup()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdpVariant, blockPickup, anyStores])

  useEffect(() => {
    checkPickup()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  return (
    <div className={styles.wrap}>
      <div className={styles.opt}>
        <label 
          className={jnstyls([styles.opt_label, dotcheck_label])} 
          disabled={(pdpVariant && !(pdpVariant.shop_avail && pdpVariant.ship)) ? 'disabled' : undefined}
          type={'button'}
          aria-current={(!isPickup).toString()}
          opt-ship=''
        >
          <input 
            type='radio' 
            value='ship'
            disabled={(pdpVariant && !(pdpVariant.shop_avail && pdpVariant.ship)) ? 'disabled' : undefined}
            name='ship_type'
            checked={!isPickup}
            onChange={(e)=>{ handleToggle('ship')}}
          />
          <div className={dotcheck}></div>
          <span>Delivery</span>
        </label>
      </div>
      <div className={styles.opt}>
        <label 
          className={jnstyls([styles.opt_label, dotcheck_label])}
          disabled={(!pdpVariant || allowPickup ) ? undefined : 'disabled'}
          aria-current={(isPickup).toString()}
          type={'button'}
          opt-pickup=''
        >
          <input 
            type='radio' 
            value='pickup'
            disabled={(!pdpVariant || allowPickup ) ? undefined : 'disabled'}
            name='ship_type'
            checked={isPickup}
            onChange={(e)=>{ handleToggle('pickup')}}
          />
          <div className={dotcheck}></div>
          <span>Pick-up</span>
          {(pdpVariant && !isPickup && nearInstock.length > 0) && (
            <span className={styles.pick_stock}>In-Stock Near You</span>
          )}
        </label>

        
        <div className={styles.pickup}>
          {(isPickup && !pdpVariant) && (
            <>Select size to view store availability</>
          )}
          {(isPickup && pdpVariant && !blockPickup) && (
            <PickupStores />
          )}
        </div>
      </div>

      {(!isPickup && (locate?.shipIntl || locate?.country !== 'US')) && (
        <div className={jnstyls([styles.intl, styles.intl_new])}>
          {(locate?.shipIntl && locate?.currStores.length < 1) && (
            <button 
              className={jnstyls([aria_btn, bubb_btn, styles.bubb_btn])} 
              aria-pressed={'true'}
              aria-label={'Ships internationally'}
              onClick={(e)=> setIntl(false)}
              type={'button'}
            >
              SHIP INTL
              <IconClose icon_classes={''} />
            </button>
          )}
          {(!locate?.shipIntl && locate?.country !== 'US') && (
            <button 
              className={jnstyls([aria_btn, bubb_btn, styles.intl_off])} 
              aria-pressed={'true'}
              aria-label={'Ships internationally'}
              onClick={(e)=> setIntl(true)}
              type={'button'}
            >
              Show Ship Intl
            </button>
          )}
        </div>
      )}

    </div>
  )
}

export default ShipPickup


