
import { useStaticQuery, graphql } from "gatsby"

export const useStoresQuery = () => {

  const query = useStaticQuery(
    graphql`
      {
        store(handle: { eq: "our-stores" }) {
          blocks
        }
      }
    `
  )

  

  const results = query.store.blocks.find( x => x.type === "regions").settings.regions

  return results
}

// const results = query.allStore.edges.filter(x => x.node.handle != 'our-stores').map(x => x.node)

// {
//   allStore {
//     edges {
//       node {
//         handle
//         title
//         region
//         short_name
//         address_line_1
//         address_line_2
//         address_city
//         address_state
//         address_zip
//         gmaps
//         coordinates
//         placeholder
        
//         avail_mens
//         avail_womens
//         pickup_enabled
//         avail_curb_copy
//         hours
//         phone
//       }
//     }
//   }
// }