import * as React from "react"
import { useProduct } from '~/contexts/ProductContext'

import * as styles from "~/styles/pdp/sizes_alts.module.scss"


const FitNotice = () => {
  const { product } = useProduct()
  const { handle, mid, product_line } = product


  const bryce_bottoms = [4240,4241,4243,4246]
  const bryce_shirts = [4247]
  const bryce_outers = [4242]
  const bryce_sweat = [4245]
  const sanders_large = [1228,1229,1230]

  return (
    <>
      {(handle.includes('deck-shoe')) && (
        <div className={styles.label_note}>
          This style runs large. Please check fit guide for details.
        </div>
      )}
      {(handle.includes('chukka') && product_line.mid !== 1367) && (
        <div className={styles.label_note}>
          This style runs true to size. Size down one half size if you are between sizes.
        </div>
      )}
      {(handle.includes('mueser')) && (
        <div className={styles.label_note}>
          Complimentary hemming options available at our Los Angeles and New York locations.
        </div>
      )}
      {(handle.includes('m030-jet-set')) && (
        <div className={styles.label_note}>
          M030 Medium Wash runs slightly snug. Please check fit guide for details.
        </div>
      )}
      {(handle.includes('m028-jet-set')) && (
        <div className={styles.label_note}>
          M028 Medium Wash runs slightly snug. Please check fit guide for details.
        </div>
      )}
      {(handle.includes('m030-rider-jean')) && (
        <div className={styles.label_note}>
          M030 Medium Wash runs slightly snug. Please check fit guide for details.
        </div>
      )}
      {(bryce_bottoms.includes(mid)) && (
        <div className={styles.label_note}>
          Size up two sizes from your standard size.
        </div>
      )}
      {(bryce_shirts.includes(mid)) && (
        <div className={styles.label_note}>
          This style is designed to fit close to the body. Designed with longer tails for a stay-tucked fit. Size up one size for a modern fit.
        </div>
      )}
      {(bryce_outers.includes(mid)) && (
        <div className={styles.label_note}>
          This style fits close and cropped. Size up one size for a modern fit.
        </div>
      )}
      {(bryce_sweat.includes(mid)) && (
        <div className={styles.label_note}>
          Size up one size from your standard size.
        </div>
      )}
      {product_line.mid === 1367 && (
        <div className={styles.label_note}>
          This style runs true to size.
        </div>
      )}
      {sanders_large.includes(product_line.mid) && (
        <div className={styles.label_note}>
          This style runs large. Please check fit guide for details.
        </div>
      )}
    </>
  )
}

export default FitNotice


