import React, { useEffect, useState } from "react"
import { useTheme } from '~/contexts/ThemeContext'
import { useProduct } from '~/contexts/ProductContext'
import useCartApi from '~/utils/use-cart'
import { jnstyls } from '~/helpers/helpers'

// import PickupInfo from '~/components/pdp/pickupInfo'

import * as pdp_styles from "~/styles/pdp/pdp.module.scss"
import * as styles from "~/styles/pdp/cta_bis.module.scss"

const Cta = ({ sticky=false }) => {
  const { 
    pdpVariant, setQuick, setFreeze, setShowGiftCard, giftInfo, setGiftInfo, 
    cartRecs, setCartRecs
  } = useTheme()
  const { 
    product, attributes, recProds, isPickup, availStoreName, blockPickup, 
    anyStores, setShowStoresPanel, currStore
  } = useProduct()
  const { changeCart } = useCartApi()

  const { is_gift, csoon, is_preorder, tags } = product

  
  const [isDisable, setIsDisable] = useState(true)
  const [shipTest, setShipTest] = useState(false)

  let display_price = (is_gift && pdpVariant) ? pdpVariant.title.replace('$','') 
                        : (product.first_product_price < product.first_full_price) || product.first_full_price === 0
                        ? product.first_product_price 
                        : product.first_full_price

  if ( product.first_product_price.toString().includes('.')) {
    display_price = parseFloat(product.first_product_price).toFixed(2)
  }

  const hasattrs = (attributes && attributes.hasOwnProperty('attributes'))

  const fcart = ( cta_var, vars=false ) => {
    if (product.handle === 'gift' && !giftInfo) {
      setShowGiftCard(true)
      setGiftInfo(false)
      return
    }
    let obj = vars ? vars : attributes

    if (!hasattrs && !!availStoreName && isPickup) {
      obj = {attributes: [{key: 'Pickup Location', value: availStoreName}]}
    }

    setQuick(false) 
    setFreeze(false)

    // isTest was set to true here and not checking
    changeCart(cta_var, obj, false, product, pdpVariant, true)

    if (!cartRecs.find(x => x.shop_id === product.shop_id)) {
      const recs_obj = {shop_id: product.shop_id, recs: recProds, outfits: product.outfits}
      let new_recs = [...cartRecs, recs_obj]
      setCartRecs(new_recs)
      const stor_obj = {recs: new_recs, time: Date.now()}
      localStorage.setItem('cartRecs', JSON.stringify(stor_obj))
    }
  }

  const checkDisable = () => {
    if (!pdpVariant) {
      setIsDisable(true)
      return
    }
    
    if (is_gift) {
      setIsDisable(false)
      return
    }

    if (!isPickup) {
      setIsDisable(!(pdpVariant.shop_avail && pdpVariant.ship))
      return
    }

    if (isPickup && !availStoreName) setIsDisable(true)
    
    if (isPickup && !hasattrs && !!availStoreName) {
      if (!shipTest) {
        setIsDisable(!(pdpVariant.shop_avail && pdpVariant.pickup))
        return
      }

      setIsDisable(!(pdpVariant.shop_avail && pdpVariant.pickup) && !!availStoreName)
      return
    }
  }

  const checkPickup = () => {
    const from_tags = tags && !(csoon || tags.includes('callout--getgood'))
    return !blockPickup && !is_gift && from_tags
  }

  const handleInstore = () => {
    setShowStoresPanel(true)
    
    window._conv_q = window._conv_q || []
    // Goal: 100464617 = Pickup: Stores Panel
    window._conv_q.push(["triggerConversion", "100464617"])

    // after test set opening stores panel evt to setShowStoresPanel(true)
  }

  useEffect(() => {
    setShipTest(sessionStorage.getItem('ship') || sessionStorage.getItem('shipt'))
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!pdpVariant) {
      setIsDisable(true)
      return
    }
    checkDisable()
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdpVariant, availStoreName, isPickup, currStore])

  useEffect(() => {
    if (!giftInfo) return
    
    if (!giftInfo.edit) {
      fcart(pdpVariant.id, giftInfo)
      setShowGiftCard(false)
      setGiftInfo(false)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftInfo])
  
  return (      
    <div 
      className={jnstyls([styles.cta_avail, styles.active, sticky ? styles.stck_cta : '', 'ctawr'])} 
      id="pdp-cta-available"
    >
      <div>
        <div className={pdp_styles.cta_error} aria-live="polite"></div> 
        {(!shipTest && checkPickup()) && (
          <div className={styles.curb_selectors}>
            <button
              className={`aria_btn`}
              data-uipop-btn="stores"
              disabled={(pdpVariant && pdpVariant?.pickup && anyStores?.length > 0) ? undefined : 'disabled'}
              type="button"
              onClick={(e)=> handleInstore()}
            >
              In-Store Pickup
            </button>
          </div>
        )}
      </div>

      <button
        className={`${styles.cta_btn} `}
        name="add"
        {...(product.handle === 'gift' ? { 
          'data-uipop-btn': 'giftcard', 
          type: 'button'
        } : {
          type: 'button',
          'data-add-to-cart': '',
          'data-uipop-btn': 'add_cart'
        })}
        disabled={isDisable ? 'disabled' : undefined}
        data-preorder={is_preorder ? '' : undefined}
        onClick={(e) => isDisable ? null : fcart(pdpVariant.id) }
      >
        {pdpVariant ? (
          <>
            <span className={jnstyls([styles.text, styles.active])}>
              {csoon && (
                'Join the Waitlist'
              )}
              {!csoon && (
                <>
                  {isPickup ? 'PICK-UP IN STORE' : 'Add to Cart'}
                </>
              )}
            </span>
            <span className={styles.price}>
              ${display_price}
            </span>
          </>
        ) : (
          'Select Size to Add to Cart'
        )}
      </button>
      
    </div>
  )
}

export default Cta


