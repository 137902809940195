import React, { useEffect, useRef } from "react";
import { useTheme } from '~/contexts/ThemeContext';
import IconClose from '~/components/icons/close'
import IconCart from '~/components/icons/cart'

import { aria_btn, cta_link, solid_btn } from "~/styles/app.module.scss"
import { cart_qty } from "~/styles/header.module.scss"
import * as styles from "~/styles/panels/cart.module.scss"


const CartNotif = () => {
  const { cart, setCartOpen, cartChanging, setCartChanging, 
          cartError, setCartError } = useTheme()

  const cart_lines = (cart && cart.lines.edges.length) ? cart.lines.edges : []
  const cartQty = cart_lines.length ? cart_lines.reduce((total, x) => total + x.node.quantity, 0) : 0
  const total = cart ? parseFloat(cart.estimatedCost.subtotalAmount.amount) : 0
  const free_ship = cart && total > 150
  const node = !cartChanging && cart.last ? cart_lines.find( x => x.node.merchandise.id === cart.last) : false
  
  const notifRef = useRef(null)

  let line, item, product, title, color, pickup, is_gift, is_vintage, has_hem;
  let gift_info = false
  if (node) {
    line = node.node
    item = line.merchandise
    product = item.product
    title = product.mPl ? product.mPl.value : product.title
    color = product.mColor ? product.mColor.value : false
    pickup = (line.attributes && line.attributes.length > 0 && line.attributes.find(x => x.key === 'Pickup Location')) || false
    is_gift = product.handle.includes('gift')
    is_vintage = product.is_vintage
    has_hem = (line.attributes && line.attributes.length && line.attributes.find(x => x.key === 'Hem')) || false
    
    if (is_gift && product.handle === 'gift') {
      gift_info = {
        name: line.attributes.find(x => x.key === 'Recipient Name') || false,
        email: line.attributes.find(x => x.key === 'Recipient Email') || false,
        date: line.attributes.find(x => x.key === 'Delivery Date') || false,
        message: line.attributes.find(x => x.key === 'Message') || false,
      }
    }
  }

  const price_format = (price) => {
    const fprice = price.includes('.') && price.includes('.0') ? parseFloat(price).toFixed(0) : parseFloat(price).toFixed(2)
    return `$${fprice}`
  }

  const handleClick = () => {
    notifRef.current.setAttribute('aria-hidden', 'true')
    setTimeout(() => {
      setCartOpen(true)
    }, 200)
  }

  const handleClose = () => {
    notifRef.current.setAttribute('aria-hidden', 'true')
    setTimeout(() => {
      setCartOpen(false)
      setCartChanging(false)
      setCartError(false)
    }, 200)
  }

  useEffect(() => {
    const header = document.querySelector('#header')
    const notif_el = notifRef.current 

    if ( header && header.style.transform === 'translate(0px, 0%)' ) {
      notif_el.style.top = `${header.clientHeight}px`
    }
    setTimeout(() => {
      notif_el.setAttribute('aria-hidden', 'false')
    }, 100)

    setTimeout(() => {
      if (document.querySelector(`.${styles.notif}`)) {
        notif_el.setAttribute('aria-hidden', 'true')
        setCartOpen(false)
        setCartChanging(false)
      }
    }, 3500)
    // setTimeout(() => {
    //   if (document.querySelector(`.${styles.notif}`)) {
    //     setCartOpen(false)
    //     setCartChanging(false)
    //     setCartError(false)
    //   }
    // }, 3700)
  }, [setCartOpen, setCartChanging, setCartError]);

  return (
    <div 
      className={styles.notif} 
      ref={notifRef}
      aria-hidden={'true'}
      cart-notif=""
    >
      <button 
        className={[aria_btn, styles.close].join(' ')}
        aria-label="Close cart" 
        onClick={(e)=> {handleClose()} }
      >
        <IconClose icon_classes={styles.icon_close} />
      </button>
      <div className={styles.head}>
        <div className={styles.head_icon}>
          <IconCart icon_classes={styles.icon_cart}></IconCart>
          {cartQty > 0 && (
            <div className={[cart_qty, styles.cart_qty].join(' ')}>{cartQty}</div>
          )}
        </div>

        {(cartQty > 0 && !free_ship) ? (
          <div className={styles.free_ship}>
            You’re ${150 - total} away from free shipping
          </div>
        ) : (cartQty > 0 && free_ship ) ? (
          <div className={styles.free_ship}>
            Your order qualifies for free U.S. shipping
          </div>
        ) : (
          <div className={styles.free_ship}>
            Free shipping on orders over $150
          </div>
        )}
        
        {cartQty > 0 && (
          <div className={styles.progress}>
            <div className={styles.prog_bar} style={{width: free_ship ? '100%' : `${(150 - total)/150}%`}}></div>
          </div>
        )}
      </div>

      <div className={styles.notif_content} style={{maxHeight: node ? '50vh' : '0'}}>
        {node && (
          <div 
            className={styles.item}
          >
            <div
              className={[styles.image].join(' ')}
            >
              <img 
                className={styles.img} 
                src={item.image.url.replace('.jpg','_400x.jpg')}
                alt={`Buck Mason ${product.title}`} 
                height="600" 
                width="450" 
              />
            </div>

            <div className={styles.details}>
              <div className={styles.title_price}>
                <div className={[aria_btn, styles.title].join(' ')} href="{{ item.url }}" uipop-close=''>
                  {title}
                </div>
                {item.priceV2 && (
                  <div className={styles.price}>
                    {price_format(item.priceV2.amount)}
                  </div>
                )}
              </div>

              {(color && !is_vintage) && (
                <div className={styles.color}>{color}</div>
              )}

              {(!product.single && item.title) && (
                <div className={styles.size}>{item.title}</div>
              )}

              {has_hem && (
                <div className={styles.hem}>
                  {has_hem.key}: {has_hem.value}" Inseam
                </div>
              )}

              {product.handle === 'gift' && (
                <div className={styles.gift}>
                  {gift_info.name && (
                    <p>
                      <strong>
                        TO:
                      </strong>
                      {gift_info.name.value}
                    </p>
                  )}
                  {gift_info.email && (
                    <p>
                      ({gift_info.email.value})
                    </p>
                  )}
                  {gift_info.date && (
                    <p>
                      {gift_info.date.value}
                    </p>
                  )}
                  {gift_info.message && (
                    <p>
                      {gift_info.message.value}
                    </p>
                  )}
                  <div style={{marginTop: '1.5em'}}>
                    <button 
                      className={[aria_btn, cta_link].join(' ')}
                      data-uipop-btn="giftcard" 
                      aria-label="Edit recipient details - opens a dialog" 
                      tabindex="0" 
                    >
                      Edit
                    </button>
                  </div>
                </div>
              )}

              {product.handle !== 'gift' && (
                <div className={styles.delivery}>
                  {pickup ? `Pick up At ${pickup.value}`: 'Ship to Address'}
                </div>
              )}

              {(product.tags.includes('finalsale') || has_hem) && (
                <div className={styles.final}>
                  Final sale: This item is not returnable.
                </div>
              )}
            </div>
          </div>

        )}
      </div>
      {!cartChanging && (
        <div className={styles.foot}>
          <button 
            className={solid_btn}
            onClick={(e)=> {handleClick()} }
          >
            View Cart
          </button>
        </div>
      )}
      {cartChanging && (
        <div className={`${styles.changing} ${cartError ? styles.errorbg : ''}`}>
          {cartError ? (
            <div className={styles.error}>
              <div className={styles.title}>
                Unable to add to cart
              </div>
              <ul>
                {cartError.map( (item,index) => 
                  <li key={`error-${index}`}>
                    {item}
                  </li>
                )}
              </ul>
            </div>
          ) : (
            <div>Adding to Cart</div>
          )}
        </div>
      )}
    </div>
  )
}

export default CartNotif