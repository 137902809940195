// extracted by mini-css-extract-plugin
export var active = "cta_bis-module--active--7c45b";
export var bis_notif = "cta_bis-module--bis_notif--aea0b";
export var bis_text = "cta_bis-module--bis_text--77ad5";
export var close = "cta_bis-module--close--64420";
export var cta_avail = "cta_bis-module--cta_avail--df862";
export var cta_btn = "cta_bis-module--cta_btn--85146";
export var cta_csoon = "cta_bis-module--cta_csoon--01dfb";
export var cta_csoon_novar = "cta_bis-module--cta_csoon_novar--b8658";
export var cta_unavail = "cta_bis-module--cta_unavail--8fa93";
export var curb_selectors = "cta_bis-module--curb_selectors--9fef7";
export var disabled = "cta_bis-module--disabled--9d4d2";
export var email = "cta_bis-module--email--b9eb3";
export var icon_close = "cta_bis-module--icon_close--21ce6";
export var info = "cta_bis-module--info--41609";
export var news_opt = "cta_bis-module--news_opt--b0a1d";
export var pdp_label = "cta_bis-module--pdp_label--dfeaf";
export var price = "cta_bis-module--price--b2b7d";
export var stck_bis = "cta_bis-module--stck_bis--29d07";
export var stck_cta = "cta_bis-module--stck_cta--d73d7";
export var sticky_closewr = "cta_bis-module--sticky_closewr--54613";
export var sticky_cta = "cta_bis-module--sticky_cta--4f3b4";
export var sticky_sizes = "cta_bis-module--sticky_sizes--78e35";
export var text = "cta_bis-module--text--d35b4";