// extracted by mini-css-extract-plugin
export var bubb_btn = "pickup-module--bubb_btn--b7d5c";
export var divide = "pickup-module--divide--02a4f";
export var edd = "pickup-module--edd--11b18";
export var free_ship = "pickup-module--free_ship--12bd4";
export var intl = "pickup-module--intl--140eb";
export var intl_new = "pickup-module--intl_new--9e80b";
export var intl_off = "pickup-module--intl_off--85452";
export var link_btn = "pickup-module--link_btn--29dd9";
export var opt = "pickup-module--opt--d56d0";
export var opt_label = "pickup-module--opt_label--300bf";
export var pick_stock = "pickup-module--pick_stock--3ea2c";
export var pickup = "pickup-module--pickup--a9bcf";
export var pickup_stores = "pickup-module--pickup_stores--74780";
export var wrap = "pickup-module--wrap--54e57";