import * as React from "react";

const IconAccount = ({ icon_classes }) => {
  return (
    <svg className={icon_classes} aria-hidden="true" focusable="false" role="presentation" x="0px" y="0px" viewBox="0 0 126 126" style={{enableBackground: 'new 0 0 126 126'}}>
      <path d="M5.4,123.2l0-4.4c0,0,0,0,0,0c0-6.4,0.3-10.9,2.1-14.3c1.7-3.2,5.1-5.7,12.2-7.4c0.3-0.1,0.6-0.1,0.9-0.2l0.1,0l10.9-2.8l0.2,0c3.8-1.1,7.2-2.4,10-4.1l0.1-0.1c2.8-1.7,5-3.9,5.9-7l0-0.1c0.9-3.1,0.2-6.7-1.9-10.6l-0.1-0.1C34.4,50.4,33,33.8,37.1,22.8C41.2,12,50.9,6.1,62.9,6.1h0.2c12,0,21.7,5.9,25.8,16.8c4.1,11,2.7,27.5-8.8,49.2L80,72.2c-2.1,3.9-2.8,7.5-1.9,10.6l0,0.1c0.9,3.1,3.1,5.3,5.9,7l0.1,0.1c2.8,1.7,6.3,3,10,4.1l0.2,0l10.9,2.8l0.1,0c0.3,0.1,0.6,0.1,0.9,0.2c7.1,1.7,10.5,4.2,12.2,7.4c1.8,3.4,2.1,7.9,2.1,14.3c0,0,0,0,0,0l0,4.4" />
    </svg>
  )
}

export default IconAccount

