import Geoloc from '~/utils/geoloc'
import { useTheme } from '~/contexts/ThemeContext';

const SetLoc = () => {
  const { getGeo, mapObj } = Geoloc()
  const { locate, setLocate } = useTheme()

  const getLoc = async (noreq=false) => {
    const loc = await getGeo(noreq, locate)
    setLocate(loc)
    return loc
  }

  const checkLoc = () => {
    setTimeout(()=>{
      let savloc = localStorage.getItem('locate')
      if (savloc) {
        savloc = JSON.parse(savloc.split('|')[1])
      }
      if (savloc && savloc.zip && savloc.country) return
      getLoc('skipip')
    }, 1000)
  }

  const checkZip = async (zip) => {
    const map_addy = await mapObj(zip)
    const testZip = map_addy?.address_components?.find( x => x.types.includes("postal_code"))
    const testCountry = map_addy?.address_components?.find( x => x.types.includes("country"))

    if (testZip) {
      const loc = {
        ...locate,
        usrZip: testZip.long_name,
        usrCountry: testCountry.short_name
      }
      setLocate(loc)
      return true
    }

    if (locate.usrZip !== null) {
      const loc = {
        ...locate,
        usrZip: null,
        usrCountry: null
      }
      setLocate(loc)
    }
    return false
  }

  const setMapCoords = async (storeZip=false) => {
    let zip = !!locate.usrZip ? locate.usrZip : locate.zip
    if (storeZip) zip = storeZip
    
    const map_addy = await mapObj(zip.split('-')[0])
    return map_addy.geometry.location
  }

  const getDistances = async (regions, storeZip=false) => {
    const new_regions = [...regions]
    const coords = await setMapCoords(storeZip)
    new_regions.forEach( region => {
      let shortest = false
      region.stores.forEach( store => {
        if (!store.coordinates) {
          store.distance = false
          return
        }
        const dest = new window.google.maps.LatLng(store.coordinates[0], store.coordinates[1])
        const distance = window.google.maps.geometry.spherical.computeDistanceBetween(coords, dest)
        let raw_dist = (distance * 0.000621371).toFixed(2)
        raw_dist = raw_dist > 100 ? Math.round(raw_dist) : parseFloat(raw_dist)
        store.distance = raw_dist
        if (raw_dist < shortest || !shortest) shortest = raw_dist
      })
      region.distance = shortest
      region.stores = storesSort(region.stores)
    })

    return storesSort(new_regions)
  }

  const storesSort = (items) => {
    return items.sort((a, b) => {
      if (a.distance === false && b.distance !== false) return 1;
      if (a.distance !== false && b.distance === false) return -1; 
      if (a.distance === false && b.distance === false) return 0;
      return a.distance - b.distance; 
    });
  }

  

  return { getLoc, checkLoc, checkZip, getDistances, storesSort };
};

export default SetLoc;