
const useLastViewsHook = (cut_first=false) => {

  const setLasts = (product) => {
    let lasts = {m: [], w: [], b: [], ps: []}
    if (localStorage.getItem('lstvws')) {
      lasts = JSON.parse(localStorage.getItem('lstvws'))
    }

    const obj = {
      id: product.mid,
      mid: product.mid,
      h: product.handle,
      g: product.gender,
      t: product.title,
      i: product.image,
      pln: product.pl_name,
      c: product.color?.name,
      ca: product.category,
      fpp: product.first_product_price,
      ffp: product.first_full_price,
      sid: product.shop_id,
      v: product.variants,
    }

    let to_change = [product.gender,'b']
    if (product.gender === 'u') to_change = ['m','w','b']
    to_change.forEach(gend => {
      let gend_recents = lasts[gend].filter(x => x !== obj.id)
      gend_recents.unshift(obj.id)
      lasts[gend] = gend_recents.slice(0, gend === 'b' ? 18 : 9)
    })
    const uniq_ids = [...new Set(lasts.m.concat(lasts.w).concat(lasts.b))]
    if (!lasts.ps.find(x => x.id === obj.id)) lasts.ps.push(obj)
    lasts.ps = lasts.ps.filter(x => uniq_ids.includes(x.id))
    localStorage.setItem('lstvws', JSON.stringify(lasts))
  }

  const getLasts = ({product_id=null, gender='m'} = {}) => {
    let lasts = {m: [], w: [], b: [], ps: []}
    if (localStorage.getItem('lstvws')) {
      lasts = JSON.parse(localStorage.getItem('lstvws'))
    }
    // const gend = gender === 'u' ? 'b' : gender
    const gend = 'b'
    let gend_recents = lasts[gend].filter(id => id !== product_id && lasts.ps.find(x => x.id === id))
    if (gend_recents.length < 1) return false

    const prods = gend_recents.map(id => {
      const prod = lasts.ps.find(x => x.id === id)
      return {
        id: prod.id,
        mid: prod.mid,
        handle: prod.h,
        gender: prod.g,
        title: prod.t,
        image: prod.i,
        pl_name: prod.pln,
        color: prod.c,
        category: prod.ca,
        other: false,
        first_product_price: prod.fpp,
        first_full_price: prod.ffp,
        shop_id: prod.sid,
        variants: prod.v,
      }
    }) 
    
    return cut_first ? prods.slice(1, prods.length) : prods
  }

  return { setLasts, getLasts }

};

export default useLastViewsHook

