import { gql } from 'graphql-request'


const cart_fields = `
  id
  createdAt
  updatedAt
  lines(first: 100) {
    edges {
      node {
        id
        quantity
        merchandise {
          ... on ProductVariant {
            id
            title
            sku
            priceV2 {
              amount
              currencyCode
            }
            image {
              url
            }
            product {
              id
              title
              handle
              tags
              compareAtPriceRange {
                maxVariantPrice {
                  amount
                }
              }
              mColor: metafield(namespace: "pima", key: "color") {
                value
              }
              mPl: metafield(namespace: "pima", key: "product_line") {
                value
              }
              mPimaid: metafield(namespace: "pima", key: "product_line_id") {
                value
              }
              mCat: metafield(namespace: "pima", key: "category") {
                value
              }
            }
          }
        }
        attributes {
          key
          value
        }
      }
    }
  }
  discountCodes {
    code
    applicable
  }
  discountAllocations {
    discountedAmount {
      amount
    }
  }
  estimatedCost {
    totalAmount {
      amount
      currencyCode
    }
    subtotalAmount {
      amount
      currencyCode
    }
    totalTaxAmount {
      amount
      currencyCode
    }
    totalDutyAmount {
      amount
      currencyCode
    }
    checkoutChargeAmount {
      amount
    }
  }
`

const cart_wrap = `cart {
  ${cart_fields}
}
userErrors {
  field
  message
}`

export function q_cart_valid() {
  return gql`query ($id: ID!) {
    cart(id: $id) {
      id
      createdAt
      updatedAt
    }
  }`
}


export function q_create() {
  return gql`mutation createCart($cartInput: CartInput) {
    cartCreate(input: $cartInput) {
      ${cart_wrap}
    }
  }`
}

export function q_add() {
  return gql`
    mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
      cartLinesAdd(cartId: $cartId, lines: $lines) {
        ${cart_wrap}
      }
    }
  `
}

export function q_quantity() {
  return gql`
    mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
      cartLinesUpdate(cartId: $cartId, lines: $lines) {
        ${cart_wrap}
      }
    }
  `
}

export function q_cart_discount() {
  return gql`
    mutation cartDiscountCodesUpdate($id: ID!, $discountCodes: [String!]!) {
      cartDiscountCodesUpdate(cartId: $id, discountCodes: $discountCodes) {
        ${cart_wrap}
      }
    }
  `
}

export function q_checkout() {
  return gql`
    query checkoutURL($id: ID!) {
      cart(id: $id) {
        id
        checkoutUrl
      }
    }
  `
}

export function q_refresh() {
  return gql`query($id: ID!) {
    cart(id: $id) {
      ${cart_fields}
    }
  }`
}

export function q_metafield() {
  return gql`query($namespace: String!, $key: String!) {
    shop {
      metafield(namespace: $namespace, key: $key) {
        value
        type
      }
    }
  }`
}

export function q_login() {
  return gql`mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        message
      }
    }
  }`
}

export function q_mp_auth() {
  return gql`mutation customerAccessTokenCreateWithMultipass($multipassToken: String!) {
    customerAccessTokenCreateWithMultipass(multipassToken: $multipassToken) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        message
      }
    }
  }`
}

export function q_register() {
  return gql`mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        firstName
        lastName
        email
        acceptsMarketing
      }
      customerUserErrors {
        field
        message
      }
    }
  }`
}

export function q_renew() {
  return gql`mutation customerAccessTokenRenew($customerAccessToken: String!) {
    customerAccessTokenRenew(customerAccessToken: $customerAccessToken) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      userErrors {
        field
        message
      }
    }
  }`
}

export function q_recover() {
  return gql`mutation customerRecover($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        message
      }
    }
  }`
}

export function q_reset() {
  return gql`mutation customerResetByUrl($password: String!, $resetUrl: URL!) {
    customerResetByUrl(password: $password, resetUrl: $resetUrl) {
      customer {
        id
        firstName
        lastName
        email
        numberOfOrders
        acceptsMarketing
        orders(first:250) {
          edges {
            node {
              subtotalPrice {
                amount
              }
            }
          }
        }
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        message
      }
    }
  }`
}

export function q_customerId() {
  return gql`query ($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      firstName
      lastName
      email
      numberOfOrders
      acceptsMarketing
      tags
      addresses(first: 250) {
        edges {
          node {
            id
            name
            firstName
            lastName
            company
            formatted
            address1
            address2
            city
            province
            provinceCode
            zip
            country
          }
        }
      }
      defaultAddress {
        id
        name
        firstName
        lastName
        company
        formatted
        address1
        address2
        city
        province
        provinceCode
        zip
        country
        phone
      }
      orders(first:250) {
        edges {
          node {
            subtotalPrice {
              amount
            }
          }
        }
      }
    }
  }`
}

export function q_orders() {
  return gql`query ($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      orders(first: 250) {
        edges {
          node {
            id
            processedAt
            fulfillmentStatus
            name
            email
            phone
            canceledAt
            shippingAddress {
              name
              company
              formatted
            }
            billingAddress {
              name
              company
              formatted
            }
            lineItems(first:250) {
              edges {
                node {
                  title
                  
                  quantity
                  originalTotalPrice {
                    amount
                  }
                  discountedTotalPrice {
                    amount
                  }
                  discountAllocations {
                    allocatedAmount {
                      amount
                    }
                    discountApplication {
                      allocationMethod
                      value {
                        __typename
                      }
                    }
                  }
                  variant {
                    title
                    id
                    product {
                      images(first:10) {
                        edges {
                          node {
                            url
                            altText
                          }
                        }
                      }
                      mColor: metafield(namespace: "pima", key: "color") {
                        value
                      }
                      mPl: metafield(namespace: "pima", key: "product_line") {
                        value
                      }
                    }
                  }
                  customAttributes {
                    key
                    value
                  }
                }
              }
            }
            financialStatus
            totalShippingPrice {
              amount
            }
            totalTax {
              amount
            }
            subtotalPrice {
              amount
            }
            totalPrice {
              amount
            }
            totalRefunded {
              amount
            }
          }
        }
      }
    }
  }`
}

export function q_addy_del() {
  return gql`mutation customerAddressDelete($customerAccessToken: String!, $id: ID!) {
    customerAddressDelete(customerAccessToken: $customerAccessToken, id: $id) {
      deletedCustomerAddressId
      customerUserErrors {
        field
        message
      }
    }
  }`
}

export function q_addy_new() {
  return gql`mutation customerAddressCreate($address: MailingAddressInput!, $customerAccessToken: String!) {
    customerAddressCreate(address: $address, customerAccessToken: $customerAccessToken) {
      customerAddress {
        id
      }
      customerUserErrors {
        field
        message
      }
    }
  }`
}

export function q_addy_upd() {
  return gql`mutation customerAddressUpdate($address: MailingAddressInput!, $customerAccessToken: String!, $id: ID!) {
    customerAddressUpdate(address: $address, customerAccessToken: $customerAccessToken, id: $id) {
      customerAddress {
        id
      }
      customerUserErrors {
        field
        message
      }
    }
  }`
}

export function q_addy_def() {
  return gql`mutation customerDefaultAddressUpdate($addressId: ID!, $customerAccessToken: String!) {
    customerDefaultAddressUpdate(addressId: $addressId, customerAccessToken: $customerAccessToken) {
      customer {
        id
      }
      customerUserErrors {
        field
        message
      }
    }
  }`
}

export function q_addy_countries() {
  return gql`{
    localization {
      availableCountries {
        name
      }
    }
  }`
}







