
const useRecommendHook = () => {

  const getRecs = async (product_id, product_line_id, outfit_ids=[]) => {
    const api_req = await fetch(`https://cdn.buckmason.com/recommends/${product_line_id}.json`)
    if (!api_req.ok) return []
    const data = await api_req.json()

    if (!data || api_req.status !== 200) {
      return []
    }

    return new Promise((resolve, reject) => {
      if (!data || api_req.status !== 200) {
        resolve([])
      }
      const recs = build_recs(product_id, data, outfit_ids)
      resolve(recs)
    })
  }

  const build_recs = (product_id, data, outfit_ids) => {
    let prod_recs = (data.products && data.products.find(x => x.id === product_id)) || []
    prod_recs = shuffle(prod_recs.recs).slice(0, 6)

    let added_ids = prod_recs.map(x => x.mid)
    let pl_ids = [...new Set(data.product_line.map(x => x.mid) )]
    let plrecs_data = pl_ids.map(id => data.product_line.find(x => x.mid === id))
    let pl_recs = shuffle(plrecs_data.filter(x => !!x && !added_ids.includes(x.mid)))

    if (prod_recs.length < 4) {
      const to_get = 4 - prod_recs.length
      const cap = 10 + to_get
      pl_recs = pl_recs.slice(0, cap)
    } else {
      pl_recs = pl_recs.slice(0, 10)
    }

    let recs = prod_recs.concat(pl_recs)
    added_ids = recs.map(x => x.mid)
    const other_recs = recs.filter(x => x.other)

    if (other_recs.length > 1) {
      recs = recs.filter(x => !x.other)
      recs.push(other_recs[0])
      const other_replace = plrecs_data.filter(x => !x.other && !added_ids.includes(x.mid)).slice(0, (other_recs.length - 1))
      recs = recs.concat(other_replace)
    }
    
    return shuffle(recs).slice(0,12)
  }

  const shuffle = (recs) => {
    return recs && recs.length > 0 ? recs.filter(x => !!x).sort(() => 0.5 - Math.random()) : []
  }

  return { getRecs }
};

export default useRecommendHook
