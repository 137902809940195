import * as React from "react";

const IconSearch = ({ icon_classes }) => {
  return (
    <svg className={icon_classes} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 126 126" aria-hidden="true" focusable="false" style={{enableBackground: 'new 0 0 126 126'}}>
      <circle cx="67.5" cy="57.4" r="38.3"/>
      <path d="M3.6,121.7"/>
      <line x1="5.7" y1="123.2" x2="42.5" y2="86.4"/>
    </svg>
  )
}

export default IconSearch
