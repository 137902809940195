import { useTheme } from '~/contexts/ThemeContext';
import QlReq from '~/utils/ql-req'
import * as Queries from '~/helpers/ql-queries'
import useAnalyticsHook from '~/utils/analytics'
import useTestFunnelHook from '~/utils/test-funnel'
import { reShopId } from '~/helpers/helpers'

const useCartApi = () => {
  const { cart, setCart, cartOpen, setCartClose, isMobile,
          setCartOpen, setCartChanging, setCartError, 
          setStoresOpen } = useTheme()

  const { fnnl_cart } = useTestFunnelHook()

  const { 
    ana_cart_add,
    ana_cart_remove
  } = useAnalyticsHook()

  const changeCart = async (var_id, info=false, qty=false, product=false, variant=false, isTest=false) => {
    const is_quick = (typeof document !== 'undefined') && !!document.querySelector('[id="ar_sidebar-p-prod"][aria-hidden="false"]')
    setCartChanging(true)
    setStoresOpen(false)

    if ( !cart ) {
      createCart(var_id, info, isTest)
      setCartChanging(true)
      setCartOpen(!isMobile ? true : 'notif') 
      ana_cart_add(product, variant, is_quick)
      return
    }

    addToCart(var_id, info, isTest)
    setCartChanging(true)
    setCartOpen(!isMobile ? true : 'notif')   
    ana_cart_add(product, variant, is_quick)  
  };

  const buildVars = (var_id, info, qty) => {
    const prod = {
      quantity: qty,
      merchandiseId: `gid://shopify/ProductVariant/${var_id}`
    }

    if ( info && Object.keys(info).includes('attributes') ) {
      prod['attributes'] = info.attributes
    }
    if ( info && Object.keys(info).includes('properties') ) {
      prod['properties'] = info.properties
    }
    return prod
  }

  const createCart = async (var_id, info, isTest=false) => {
    const lines = buildVars(var_id, info, 1)
    const vars = {
      cartInput: {
        lines: [lines]
      }
    }

    const query = Queries.q_create()
    const req = await QlReq.post(query, vars)

    const ispickup = info.hasOwnProperty('attributes') && info.attributes.find(x => x.key === 'Pickup Location')

    if ( req.cartCreate.userErrors.length ) {
      handleError( req.cartCreate.userErrors.map(x => x.message) )
    } else {
      const newcart = req.cartCreate.cart
      newcart['last'] = `gid://shopify/ProductVariant/${var_id}`
      newcart['sendAdd'] = true
      updateState(newcart)
      if (sessionStorage.getItem('autodis')) addDiscount(newcart.id)

      if (isTest) fnnl_cart(newcart, var_id, 1, {pickup: !!ispickup, isTest: isTest})
    }
  }

  // const checkExisting = (var_id, info) => {
  //   const item = cart.lines.edges.find( x => {
  //     const same_id = x.node.merchandise.id === `gid://shopify/ProductVariant/${var_id}`
  //     const exist_attr = {attributes: x.node.attributes}
  //     const same_attr = JSON.stringify(exist_attr) === JSON.stringify(info)

  //     return same_id && same_attr
  //   })
  //   const is_gift = item && item.node.merchandise.product.handle === 'gift'
  //   return is_gift ? false : item
  // }

  const refreshCart = async ( var_id, info, qty ) => {
    const vars = {
      id: cart.id
    }
    const query = Queries.q_refresh()
    const req = await QlReq.post(query, vars)

    if ( req.cart.lines.edges.length ) {
      const new_cart = req.cart
      new_cart['checkout'] = await getCheckout( new_cart.id )
      new_cart.lines.edges.forEach( x => {
        x.node.merchandise.product.tags = x.node.merchandise.product.tags.filter(tag => tag === 'finalsale')
      })

      const new_line = new_cart.lines.edges.find(x => x.node.merchandise.id === var_id)
      setCart(new_cart)
      localStorage.setItem('cart', JSON.stringify(new_cart))
      sessionStorage.removeItem('lineretry')
      changeLine(new_line.node, info, qty)
    }

  }

  const changeLine = async (line, info=false, qty=false) => {
    const new_qty = (qty !== false && qty === 0) ? 0 : qty ? line.quantity + qty : line.quantity
    const new_line = {
      id: line.id,
      merchandiseId: line.merchandise.id,
      quantity: new_qty,
    }
    
    new_line['attributes'] = info ? info.attributes : line.attributes ? line.attributes : []

    const vars = {
      cartId: cart.id,
      lines: [new_line]
    }

    setCartChanging(true)

    const query = Queries.q_quantity()
    const req = await QlReq.post(query, vars)

    if ( !req.cartLinesUpdate.userErrors.length ) {
      const newcart = req.cartLinesUpdate.cart
      newcart['last'] = line.merchandise.id
      newcart['sendAdd'] = new_qty > line.quantity
      updateState(newcart, true)

      const ispickup = info && info?.attributes?.find(x => x.key === 'Pickup Location')

      if (qty !== false) {
        const cprods = JSON.parse(localStorage.getItem('cprods'))
        const cprod = cprods.find(x => reShopId(x.shop_id) === line.merchandise.product.id)
        const var_id = line.merchandise.id.replace('gid://shopify/ProductVariant/','')
        const variant = cprod.variants.find(x => x.id.toString() === var_id)

        if (qty === 0) ana_cart_remove(cprod, variant, `-${line.quantity}`)
        if (qty < 0) ana_cart_remove(cprod, variant, qty)
        if (qty > 0) ana_cart_add(cprod, variant, false)

        fnnl_cart(newcart, var_id, qty, {pickup: !!ispickup, isTest: 'change'})
      }
      return
    }

    const errors = req.cartLinesUpdate.userErrors.map(x => x.message)

    if (errors[0].includes('merchandise line with id') && errors[0].includes('does not exist')) {
      sessionStorage.setItem('lineretry','true')
      const refresh_item = cart.lines.edges.find(x => x.node.id === line.id)
      const var_id = refresh_item.node.merchandise.id
      refreshCart(var_id, info, qty)
      return
    }

    handleError( req.cartLinesUpdate.userErrors.map(x => x.message) )

    setTimeout(() => {
      setCartChanging(false)
      setCartError(false)
    }, 2000)
  }

  const editGift = async (obj) => {
    const vars = {
      cartId: cart.id,
      lines: [
        {
          id: obj.line.id,
          merchandiseId: obj.line.merchandise.id,
          quantity: obj.line.quantity,
          attributes: obj.attributes
        }
      ]
    }
    
    const query = Queries.q_quantity()
    const req = await QlReq.post(query, vars)

    const needs_err_check = req.cartLinesUpdate.cart
    needs_err_check['last'] = obj.line.merchandise.id
    updateState(needs_err_check, false)
    setCartOpen(true)
  }

  const addToCart = async (var_id, info, isTest=false) => {
    const shop_var_id = `gid://shopify/ProductVariant/${var_id}`
    const lines = buildVars(var_id, info, 1)
    const vars = {
      cartId: cart.id,
      lines: [lines]
    }
    const query = Queries.q_add()
    const req = await QlReq.post(query, vars)

    const ispickup = info.hasOwnProperty('attributes') && info.attributes.find(x => x.key === 'Pickup Location')

    if ( !req.cartLinesAdd.userErrors.length ) {
      const newcart = req.cartLinesAdd.cart
      newcart['last'] = shop_var_id
      newcart['sendAdd'] = true
      updateState(newcart)
      
      if (isTest) fnnl_cart(newcart, var_id, 1, {pickup: !!ispickup, isTest: isTest})
      return
    }

    handleError( req.cartLinesAdd.userErrors.map(x => x.message) )
    const line = req.cartLinesAdd.cart.lines.edges.find(x => x.node.merchandise.id === shop_var_id)
    if ( !line ) return

    const fail_obj = {
      fail: true,
      id: line.node.id,
      merchandise: {id: shop_var_id}
    }

    updateExistingNoLine(fail_obj, {}, 0)
  }


  const addDiscount = async (id, discount_code=false) => {
    const code = discount_code ? discount_code : sessionStorage.getItem('autodis')

    const vars = {
      id: id,
      discountCodes: [code]
    }

    const query = Queries.q_cart_discount()
    const req = await QlReq.post(query, vars)

    if ( !req.cartDiscountCodesUpdate.userErrors.length ) {
      const newcart = req.cartDiscountCodesUpdate.cart
      updateState(newcart)
      sessionStorage.removeItem('autodis')
    }
  }

  const getCheckout = async ( id ) => {
    const vars = {
      id: id
    }
    const query = Queries.q_checkout()
    const req = await QlReq.post(query, vars)

    const needs_err_check = req.cart.checkoutUrl
    return needs_err_check
  }


  const handleError = ( error ) => {
    if (cart && error[0].includes('merchandise line with id') && error[0].includes('does not exist') ) {
      console.log(error)
    }

    setCartError(error)
  }

  const updateState = async (data, open=true) => {
    if ( data.lines.edges.length ) {
      data['checkout'] = await getCheckout( data.id )
      data.lines.edges.forEach( x => {
        x.node.merchandise.product.tags = x.node.merchandise.product.tags.filter(tag => tag === 'finalsale')
      })
      setCart(data)
      
      const save_data = JSON.parse(JSON.stringify(data))
      delete save_data.sendAdd
      localStorage.setItem('cart', JSON.stringify(save_data))
    } else {
      localStorage.removeItem('cart')
      setCart(false)
    }

    setCartChanging(false)

    if ( cartOpen && data.lines.edges.length < 1 ) {
      setCartClose(true)
    }
  }

  const updateExistingNoLine = async (existing, info, qty) => {
    const new_qty = qty === 0 ? 0 : qty ? existing.quantity + qty : existing.quantity + 1
    const vars = {
      cartId: cart.id,
      lines: [
        {
          id: existing.id,
          merchandiseId: existing.merchandise.id,
          quantity: new_qty,
          attributes: existing.attributes
        }
      ]
    }
    
    const query = Queries.q_quantity()
    const req = await QlReq.post(query, vars)

    const needs_err_check = req.cartLinesUpdate.cart
    needs_err_check['last'] = existing.merchandise.id
    needs_err_check['sendAdd'] = new_qty > existing.quantity
    if (!existing.hasOwnProperty('fail')) updateState(needs_err_check, false)
  }


  return { cart, addDiscount, changeCart, changeLine, editGift };
};

export default useCartApi;