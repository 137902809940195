

const useKeyboardEvent = () => {
  // const { focustarg } = useTheme()

  const closeListen = ( callback ) => {
    const handler = (e) => {
      if (e.key === 'Escape') {
        callback();
        window.removeEventListener('keydown', handler);
      }
    }
    window.addEventListener('keydown', handler)
  }


  return { closeListen };
};

export default useKeyboardEvent;


// import { useEffect } from 'react';

// function useKeyboardEvent(callback) {
//   useEffect(() => {
//     const handler = (event) => {
//       callback(event);
//     }

//     window.addEventListener('keydown', handler);

//     // Cleanup on unmount
//     return () => {
//       window.removeEventListener('keydown', handler);
//     };
//   }, [callback]);
// }

// export default useKeyboardEvent;