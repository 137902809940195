import React, { useState, useEffect, useRef } from "react";
import { Link, navigate } from "gatsby";
import { useTheme } from '~/contexts/ThemeContext';
import { imgSize, deShopId, reShopId } from '~/helpers/helpers'
import useCartApi from '~/utils/use-cart'
import useAnalyticsHook from '~/utils/analytics'
import useTestFunnelHook from '~/utils/test-funnel'

import IconClose from '~/components/icons/close'
import IconCart from '~/components/icons/cart'
import CartUpsell from './cart-upsell'

import { aria_btn, cta_link, solid_btn, bg } from "~/styles/app.module.scss"
import { cart_qty } from "~/styles/header.module.scss"
import * as styles from "~/styles/panels/cart.module.scss"


const Cart = () => {
  const { cart, setCartOpen, cartClose, setCartClose, cartChanging, 
          setCartChanging, cartError, setCartError, showGiftCard, setShowGiftCard, 
          freeze, setGiftInfo, setFreeze, cartRecs 
        } = useTheme()

  const { changeLine } = useCartApi()
  const { elev_cart } = useAnalyticsHook()
  const { fnnl_check } = useTestFunnelHook()

  const [recProds, setRecProds] = useState(false)
  const [cartQty, setCartQty] = useState(0)
  const [cartLines, setCartLines] = useState([])
  const [total, setTotal] = useState(0)
  const [freeShip, setFreeShip] = useState(false)
  const [blockKey, setBlockKey] = useState(false)

  const bgRef = useRef(null)
  const panelRef = useRef(null)

  const recsOutfits = () => {
    const cart_lines = (cart && cart.lines?.edges?.length > 0) ? cart.lines.edges : []
    const cart_qty = cart_lines.length ? cart_lines.reduce((total, x) => total + x.node.quantity, 0) : 0
    const last_item = cart_lines.length > 0 ? cart_lines[0] : false

    const recs_arr = cartRecs || []
    let rec_prods = last_item ? recs_arr.find( x => reShopId(x.shop_id) === last_item.node.merchandise.product.id) : false
    if (rec_prods) {
      rec_prods = JSON.parse(JSON.stringify(rec_prods))
      const recs_arr = (rec_prods && rec_prods.hasOwnProperty('recs')) ? rec_prods.recs : []
      rec_prods.recs = recs_arr.sort(() => 0.5 - Math.random()).slice(0,6)
    }

    let outfits = []
    if ( (last_item && !last_item.node?.merchandise?.sku?.includes('.VIN')) && (rec_prods && rec_prods?.outfits?.length > 0) ) {
      const all_shop_ids = cart.lines?.edges?.length > 0 ? cart.lines.edges.map(x => deShopId(x.node.merchandise.product.id)) : []
      if (rec_prods.outfits && !all_shop_ids.includes(rec_prods.outfits[0].shop_id)) {
        outfits = rec_prods.outfits.filter(x => !!x && !all_shop_ids.includes(x.shop_id))
        const lastprod = last_item.node.merchandise.product
        const reftitle = lastprod.mPl ? lastprod.mPl.value : lastprod.title
        outfits.forEach(x => x['reftitle'] = reftitle)
      }
    }
    outfits = outfits.map(otf => { 
      const standcol = otf.product_line?.colors?.standard?.find(x => x.handle === otf.handle)?.name
      const vwashcol = otf.product_line?.colors?.venice_wash?.find(x => x.handle === otf.handle)?.name
      const color_name = standcol || vwashcol || ''
      return {...otf, pl_name: otf.product_line?.name, color: color_name} 
    })
    
    if (rec_prods) {
      rec_prods.outfits = outfits
    }

    setCartLines(cart_lines)
    setCartQty(cart_qty)
    setRecProds(rec_prods)
  }

  // const after_limit = (typeof window !== 'undefined') ? window.ab_after : 200
  const after_limit = 0

  const price_format = (price) => {
    const fprice = price.includes('.') && price.includes('.0') ? parseFloat(price).toFixed(0) : parseFloat(price).toFixed(2)
    return `$${fprice}`
  }

  const handleLink = (url) => {
    setCartOpen(false)
    navigate(url)
    handleClose()
  }

  const handleClose = () => {
    setCartClose(true)
    setFreeze(false)
  }

  const handleGiftEdit = (line) => {
    setGiftInfo({line: line, edit: true})
    setShowGiftCard(true)
    setBlockKey(true)
  }

  const checkTotalFreeShip = () => {
    const total_amt = cart ? parseFloat(cart.estimatedCost.subtotalAmount.amount) : 0
    setTotal(total_amt)
    let free_ship = cart && total_amt > 150

    const discnt_ship = cart?.discountCodes?.find(x => x.code === 'FIRSTSHIPPING')
    if (discnt_ship && discnt_ship.applicable) free_ship = true

    setFreeShip(free_ship)
  }

  useEffect(() => {
    const closeListen = (e) => {
      if (blockKey) return
      if (e.key === 'Escape') {
        window.removeEventListener('keydown', closeListen)
        handleClose()
      }
    }
    window.addEventListener('keydown', closeListen)

    return () => {
      window.removeEventListener('keydown', closeListen)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockKey, showGiftCard])

  useEffect(() => {
    if (blockKey && !showGiftCard) setBlockKey(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showGiftCard, blockKey])


  useEffect(() => {
    if (!cart) return

    checkTotalFreeShip()

    recsOutfits()
    elev_cart()
    fnnl_check()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  useEffect(() => {
    if (cartClose === false) return
    if (!(bgRef.current && panelRef.current)) return

    bgRef.current.setAttribute('aria-hidden', 'true')
    panelRef.current.setAttribute('aria-hidden', 'true')

    setFreeze(false)
    setCartOpen(false)
    setCartClose(false)
    if (cartError) {
      setCartError(false)
      setCartChanging(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartClose, cartError, cartChanging]);

  useEffect(() => {
    if (freeze === true) return

    setFreeze(true)
    const bg_el = bgRef.current
    const panel_el = panelRef.current

    setTimeout(() => {
      bg_el.setAttribute('aria-hidden', 'false')
      panel_el.setAttribute('aria-hidden', 'false')
    }, 100)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFreeze]);

  return (
    <>
      <button 
        className={[aria_btn, bg].join(' ')} 
        aria-hidden="true" 
        ref={bgRef} 
        onClick={(e)=> {handleClose()}}
        style={{textAlign: 'right'}}
        aria-label={'Close cart panel'}
      />
      <aside 
        className={styles.cart}
        ref={panelRef}
        role="dialog" 
        aria-modal="true"
        aria-hidden="true" 
        style={{pointerEvents: cartChanging ? 'none' : ''}}
        cart=''
      >
        <button 
          className={[aria_btn, styles.close].join(' ')}
          aria-label="Close cart" 
          onClick={(e)=> {handleClose()}}
        >
          <IconClose icon_classes={styles.icon_close} />
        </button>

        <div className={styles.head}>
          <div className={styles.head_icon}>
            <IconCart icon_classes={styles.icon_cart}></IconCart>
            {cartQty > 0 && (
              <div className={[cart_qty, styles.cart_qty].join(' ')}>{cartQty}</div>
            )}
          </div>

          {(cartQty > 0 && !freeShip) ? (
            <div className={styles.free_ship}>
              You’re ${150 - total} away from free shipping
            </div>
          ) : (cartQty > 0 && freeShip ) ? (
            <div className={styles.free_ship}>
              Your order qualifies for free U.S. shipping
            </div>
          ) : (
            <div className={styles.free_ship}>
              Free shipping on orders over $150
            </div>
          )}
          
          {cartQty > 0 && (
            <div className={styles.progress}>
              <div className={styles.prog_bar} style={{width: freeShip ? '100%' : `${(1 - (150-35)/150) * 100}%`}}></div>
            </div>
          )}
          
          {cartQty > 0 && (
            <div className={styles.count}>
              {cartQty}{` Item${cartQty > 1 ? 's' : ''}`}&nbsp;&mdash;&nbsp;{`$${total}`}
            </div>
          )}
        </div>

        {cartQty > 0 ? (
          <>
            <div className={styles.body}>
              <div className={styles.items}>

                {cartLines.map((node, index) => {
                  const line = node.node
                  const item = line.merchandise
                  const product = item.product
                  const title = product.mPl ? product.mPl.value : product.title
                  const color = product.mColor ? product.mColor.value : false
                  const pickup = (line.attributes && line.attributes.length && line.attributes.find(x => x.key === 'Pickup Location')) || false
                  const is_gift = product.handle === 'gift'
                  const is_vintage = product.is_vintage
                  const has_hem = (line.attributes && line.attributes.length && line.attributes.find(x => x.key === 'Hem')) || false
                  let gift_info = false

                  if (is_gift && product.handle === 'gift') {
                    gift_info = {
                      name: line.attributes.find(x => x.key === 'Recipient Name') || false,
                      email: line.attributes.find(x => x.key === 'Recipient Email') || false,
                      date: line.attributes.find(x => x.key === 'Delivery Date') || false,
                      message: line.attributes.find(x => x.key === 'Message') || false,
                    }
                  }

                  return (
                    <div 
                      key={`${item.id}-${index}`}
                      className={styles.item}
                    >
                      <button
                        className={[aria_btn, styles.image].join(' ')}
                        onClick={(e)=>{handleLink(`/products/${product.handle}`)}}
                      >
                        <img 
                          className={styles.img} 
                          src={imgSize(item.image.url, 300)}
                          alt={`Buck Mason ${product.title}`} 
                          height="600" 
                          width="450" 
                        />
                      </button>

                      <div className={styles.details}>
                        <div className={styles.title_price}>
                          <button 
                            className={[aria_btn, styles.title].join(' ')} 
                            onClick={(e)=>{handleLink(`/products/${product.handle}`)}}
                          >
                            {title}
                          </button>
                          {item.priceV2 && (
                            <div className={styles.price}>
                              {price_format(item.priceV2.amount)}
                            </div>
                          )}
                        </div>

                        {(color && !is_vintage) && (
                          <div className={styles.color}>{color}</div>
                        )}

                        {(!product.single && item.title) && (
                          <div className={styles.size}>{item.title}</div>
                        )}

                        {has_hem && (
                          <div className={styles.hem}>
                            <div>
                              <strong>
                                {has_hem.key}:
                              </strong>
                            </div>
                            <div>
                              {has_hem.value}" Inseam
                            </div>
                          </div>
                        )}

                        {product.handle === 'gift' && (
                          <div className={styles.gift}>
                            <div className={styles.gift_info}>
                              {gift_info.name && (
                                <>
                                  <div>
                                    <strong>
                                      Recipient Name:
                                    </strong>
                                  </div>
                                  <div>
                                    {gift_info.name.value}
                                  </div>
                                </>
                              )}
                              {gift_info.email && (
                                <>
                                  <div>
                                    <strong>
                                      TO:
                                    </strong>
                                  </div>
                                  <div>
                                    ({gift_info.email.value})
                                  </div>
                                </>
                              )}
                              {gift_info.date && (
                                <>
                                  <div>
                                    <strong>
                                      Delivery Date:
                                    </strong>
                                  </div>
                                  <div>
                                    {gift_info.date.value}
                                  </div>
                                </>
                              )}
                              {gift_info.message && (
                                <>
                                  <div>
                                    <strong>
                                      Message:
                                    </strong>
                                  </div>
                                  <div>
                                    {gift_info.message.value}
                                  </div>
                                </>
                              )}
                            </div>
                            <div className={styles.gift_edit}>
                              <button 
                                type={'button'}
                                className={[aria_btn, cta_link].join(' ')}
                                aria-label="Edit recipient details - opens a dialog" 
                                onClick={(e)=>{handleGiftEdit(line)}}
                              >
                                Edit
                              </button>
                            </div>
                          </div>
                        )}

                        <div className={styles.change}>
                          <div className={styles.qty_edit}>
                            {(!is_vintage && product.handle !== 'gift') && (
                              <button 
                                type={'button'}
                                className={[aria_btn, styles.qty_btn].join(' ')}
                                aria-label="Decrease quantity"
                                onClick={ (e)=>{ changeLine(line, false, -1) } }
                              ></button>
                            )}
                            <div 
                              aria-live="polite" 
                              aria-atomic="true" 
                              aria-relevant="text" 
                            >
                              {line.quantity}
                            </div>
                            {(!is_vintage && product.handle !== 'gift') && (
                              <button 
                                type={'button'}
                                className={[aria_btn, styles.qty_btn, styles.plus].join(' ')}
                                onClick={ (e)=>{ changeLine(line, false, 1) } }
                                aria-label={'Increase quantity'}
                              ></button>
                            )}
                          </div>
                          <button 
                            type={'button'}
                            className={[aria_btn, styles.remove].join(' ')}
                            data-minicart-remove=''
                            aria-label={`Remove ${product.title} from cart`}
                            onClick={ (e)=>{ changeLine(line, false, 0) } }
                          >
                            <IconClose icon_classes={styles.icon_remove} />
                          </button>
                        </div>

                        {product.handle !== 'gift' && (
                          <div className={styles.delivery}>
                            {pickup ? `Pick up At ${pickup.value}`: 'Ship to Address'}
                          </div>
                        )}

                        {(product.tags.includes('finalsale') || has_hem) && (
                          <div className={styles.final}>
                            Final sale: This item is not returnable.
                          </div>
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>

              {(recProds) && (
                <CartUpsell recProds={recProds}/>
              )}

              {(cart) && (
                <>
                  <div className={styles.total}>
                    <div className={styles.label}>
                      Subtotal
                    </div>
                    <div className={styles.copy}>
                      {price_format(cart.estimatedCost.subtotalAmount.amount)}
                    </div>
                    <div className={styles.label}>
                      Shipping <span className={styles.label_checkout}>Final tax + shipping calculated at checkout</span>
                    </div>
                    <div className={styles.copy}>
                      {freeShip ? 'FREE' : '$8'}
                    </div>
                    {(cart.discountCodes.length > 0 && cart.discountCodes[0].applicable) && (
                      <>
                        <div className={styles.label}>
                          Discount<span className={styles.label_checkout}>Applied at checkout</span>
                        </div>
                        <div className={styles.copy}>
                          {cart.discountCodes[0].code}
                        </div>
                      </>
                    )}
                    <div className={styles.label} style={{fontWeight: 'bold'}}>
                      Estimated Total
                    </div>
                    <div className={styles.copy} style={{fontWeight: 'bold'}}>
                      {price_format(cart.estimatedCost.checkoutChargeAmount.amount)}
                    </div>
                  </div>

                  {cart.estimatedCost.totalAmount.amount > after_limit && (
                    <div id={styles.afterpay}>
                      <afterpay-placement 
                        data-locale="en_US"
                        data-currency="USD" 
                        data-amount={cart.estimatedCost.totalAmount.amount}
                        data-modal-theme="white"
                        data-logo-type="lockup"
                      ></afterpay-placement>
                    </div>
                  )}
                </>
              )}
              
              <div className={styles.returns}>
                We’ve got you &mdash; Enjoy 365 day returns
              </div>
            </div>
            <div className={styles.foot}>
              {(cart) && (
                <a className={solid_btn} href={cart.checkout}>
                  Checkout
                </a>
              )}
            </div>
          </>
        ) : (
          <CartEmpty />
        )}

        
        {cartChanging && (
          <div className={`${styles.changing} ${cartError ? styles.errorbg : ''}`}>
            {cartError ? (
              <div className={styles.error}>
                <div className={styles.title}>
                  Unable to add to cart
                </div>
                <ul>
                  {cartError.map( (item,index) => 
                    <li key={`error-${index}`}>
                      {item}
                    </li>
                  )}
                </ul>
              </div>
            ) : (
              <div>Updating Cart</div>
            )}
          </div>
        )}
      </aside>
    </>
  )
};


const CartEmpty = () => {
  const { setFreeze, setCartClose } = useTheme()

  const handleClose = () => {
    setCartClose(true)
    setFreeze(false)
  }

  return (
    <div className={styles.empty}>
      <div className={styles.message}>
        Your cart is empty
        <div className={styles.subtitle}>
          Let's change that
        </div>
      </div>
      <div className={styles.collections}>
        <Link className={styles.coll} to="/collections/best-sellers" onClick={(e)=> {handleClose()}}>
          <div className={styles.image}>
            <img 
              className={styles.img} 
              src="https://cdn.shopify.com/s/files/1/0123/5065/2473/files/Mens_EmptyCart_400x.jpg?v=1723672654" 
              width="300" 
              height="400" 
              loading="lazy" 
              decode="async" 
              alt=""
            />
          </div>
          Men's Best Sellers
        </Link>
        <Link className={styles.coll} to="/collections/womens-best-sellers" onClick={(e)=> {handleClose()}}>
          <div className={styles.image}>
            <img 
              className={styles.img} 
              src="https://cdn.shopify.com/s/files/1/0123/5065/2473/files/Womens_EmptyCart-copy_400x.jpg?v=1723672659" 
              width="300" 
              height="400" 
              loading="lazy" 
              decode="async" 
              alt=""
            />
          </div>
          Women's Best Sellers
        </Link>
      </div>
    </div>
  )
}



export default Cart



