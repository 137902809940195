import React, { useState } from "react";
import { useProduct } from '~/contexts/ProductContext'
import { jnstyls } from '~/helpers/helpers'

import SwatchGroup from '~/components/shared/swatches'

import { info, label } from "~/styles/pdp/pdp.module.scss"
import * as styles from "~/styles/shared/swatches.module.scss"


const Colorways = ({ colorClick=false }) => {
  const { product } = useProduct()
  const { handle, pl_colors, color } = product

  const inc_venice = (handle && handle.includes('venice-wash')) || (pl_colors.hasOwnProperty('venice wash'))
  const core = pl_colors.standard || []
  const venice = pl_colors.venice_wash ? pl_colors.venice_wash : []

  const [activeColor, setActiveColor] = useState(color.name);
  const colorInteraction = (name) => {
    setActiveColor(!name ? color.name : name);
  }

  const needs_labels = core.length > 0 && venice.length > 0

  return (
    <>
      {color.name && (
        <div className={jnstyls([info, styles.swatches_info])} test-prod-name={color.name}>
          <div className={label}>
            Color:
          </div>
          <div className={styles.color_name}>  
            {activeColor}
          </div>
        </div>
      )}

      {core.length > 0 && (
        <>
          {needs_labels && (
            <div className={styles.swatch_type} id="ar_label-select_core" swtchs-lbal={''}>
              {inc_venice ? 'Core Colors' : 'Colors'}
            </div>
          )}
          <SwatchGroup 
            prod_handle={handle} 
            color_group={core} 
            onInteraction={colorInteraction}
            colorClick={colorClick ? colorClick : undefined}
          />
        </>
      )}

      {venice.length > 0 && (
        <>
          {needs_labels && (
            <div className={styles.swatch_type} id="ar_label-select_venice" swtchs-lbal={''}>
              Venice Wash
            </div>
          )}
          <SwatchGroup 
            prod_handle={handle} 
            color_group={venice} 
            onInteraction={colorInteraction}
            colorClick={colorClick ? colorClick : undefined}
          />
        </>
      )}  
    </>
  )
}

export default Colorways



