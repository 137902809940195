// extracted by mini-css-extract-plugin
export var active = "sizes_alts-module--active--d78da";
export var label_note = "sizes_alts-module--label_note--d1d0b";
export var many_variants = "sizes_alts-module--many_variants--a5ef4";
export var msg_active = "sizes_alts-module--msg_active--32a09";
export var opt_single = "sizes_alts-module--opt_single--31a53";
export var option = "sizes_alts-module--option--e06f5";
export var optspc = "sizes_alts-module--optspc--6dcfd";
export var pdpSize_comingsoon = "sizes_alts-module--pdp-size_comingsoon--72e9b";
export var pdpSize_msg = "sizes_alts-module--pdp-size_msg--4a6a0";
export var pdpSize_shipstore = "sizes_alts-module--pdp-size_shipstore--980df";
export var pdpSize_shipstoreShip = "sizes_alts-module--pdp-size_shipstore-ship--b6a4a";
export var pdpSize_shipstoreStore = "sizes_alts-module--pdp-size_shipstore-store--3b1fb";
export var pdpUnavailable_msg = "sizes_alts-module--pdp-unavailable_msg--80d6f";
export var pdp_sizes = "sizes_alts-module--pdp_sizes--396c4";
export var pickonly = "sizes_alts-module--pickonly--ce93b";
export var preorder = "sizes_alts-module--preorder--e15b0";
export var qsizes = "sizes_alts-module--qsizes--51158";
export var unavailable = "sizes_alts-module--unavailable--43f17";