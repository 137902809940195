import React, { useState, useEffect, useRef } from "react";
import { Link, navigate } from "gatsby"
import { useSearchContentQuery } from "~/hooks/useSearchContentQuery"
import useKeyboardEvent from '~/utils/keyboard-evt'
import useSearch from '~/utils/use-search'
import Fuse from 'fuse.js'
import { useTheme } from '~/contexts/ThemeContext';
import useAnalyticsHook from '~/utils/analytics'
import { imgSize, isStag } from '~/helpers/helpers'

import IconClose from '~/components/icons/close'
import IconSearch from '~/components/icons/search'

import { aria_btn, solid_btn } from "~/styles/app.module.scss"
import * as tstyles from "~/styles/pdp/title.module.scss"
import * as styles from "~/styles/panels/search.module.scss"



const Search = () => {
  const { setFreeze, setSearch, analytics, setAnalytics, setBread } = useTheme()
  const content = useSearchContentQuery()
  const { buildVals, saveSearch } = useSearch()
  const { ga_search_click, elev_search, elev_select } = useAnalyticsHook()

  const [searchData, setSearchData] = useState(null)
  const [prodResults, setProdResults] = useState([])
  const [searchType, setSearchType] = useState(null)
  const [fuseIndex, setFuseIndex] = useState(null)

  const { closeListen } = useKeyboardEvent();

  const panelRef = useRef(null)
  const inptRef = useRef(null)
  const resultsRef = useRef(null)

  const fuseOptions = {
    shouldSort: true,
    useExtendedSearch: true,
    threshold: 0.35,
    keys: [
      'tags',
      'gender'
    ]
  };

  const handleClose = () => {
    const panel_el = panelRef.current

    if (!panel_el) return
    panel_el.setAttribute('aria-hidden', 'true')
    setTimeout(() => {
      setFreeze(false)
      setSearch(false)
    }, 200)
  }

  const searchClear = () => {
    setProdResults([])
    localStorage.removeItem('search')
    inptRef.current.value = ''
  }

  const handleSearch = () => {
    if (!searchData) return

    const val = inptRef.current.value.toLowerCase()

    if ( val === '' ) {
      searchClear()
      return
    }

    if ( val.length < 3 ) return

    let val_arr = val.split(' ').filter(x => !!x).map(x => { return {tags: x} })
    if (searchType) {
      val_arr.push({gender: `=${searchType}` })
    }
    let results = fuseIndex.search({$and: val_arr})
    results = results.map(x => x.item.id)

    let prods = results.map(id => searchData.products.find(x => x.mid === id))
    prods = prods.sort((a, b) => a.handle.localeCompare(b.handle))
    setProdResults(prods)
    do_saving()
  }

  const gaClick = (product=false, isPopular=false, index=false) => {
    const val = inptRef.current.value

    const term = isPopular ? isPopular.toLowerCase() : inptRef.current.value
    const type = searchType === null ? 'all' : searchType === 'm' ? 'men' : searchType === 'w' ? 'women' : searchType

    ga_search_click(term, type, !!isPopular)
    elev_search(val, prodResults)
    if (product) {
      setAnalytics({...analytics, list: 'search results'})
      elev_select(product, index, 'search results')
    }
  }

  const get_search_data = () => {
    const url = isStag() 
      ? 'https://cdn-staging.buckmason.com/search/search-json.json' 
      : 'https://cdn.buckmason.com/search/search-json.json'
    fetch(url)
      .then(res => res.json())
      .then(data => setSearchData(data)) 
  }

  const do_saving = async () => {
    const val = inptRef.current.value
    if ( val === '' ) return

    buildVals(val, searchType)
    const obj = {
      prodResults: prodResults,
      searchType: searchType
    }
    const has_saved = await saveSearch(obj)
    return has_saved
  }

  const goto_page = async () => {
    const has_saved = await do_saving()
    if (has_saved) {
      if (window.location.pathname.includes('/search')) {
        window.location = `/search`
        handleClose()
      } else {
        navigate('/search')
        handleClose()
      }
    }
  }

  const check_enter = (e) => {
    if (e.key !== 'Enter' || !searchData) return
    goto_page()
  }

  const setupFuse = () => {
    const docs = searchData.products.map(x => {
      return {
        id: x.mid,
        gender: x.gender,
        tags: x.search.map(x => x.replace('womens-',''))
      }
    })
    setFuseIndex(new Fuse(docs, fuseOptions))
  }

  useEffect(() => {
    if (!searchData) return
    if (fuseIndex) return
    setupFuse()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData]);

  useEffect(() => {
    setBread({handle: 'search', title: 'Search Results'})

    closeListen(handleClose)

    if (!searchData) get_search_data()
    if (!prodResults) localStorage.removeItem('search')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleSearch()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchType]);
  
  useEffect(() => {
    if (inptRef.current) {
      inptRef.current.focus();
    }
    setFreeze(true)
    const panel = panelRef.current
    setTimeout(() => {
      panel.setAttribute('aria-hidden', 'false')
    }, 100)
  }, [setFreeze]);

  useEffect(() => {
    if (typeof window === 'undefined') return
    
    window.addEventListener('keydown', check_enter);

    return () => {
      window.removeEventListener('keydown', check_enter);
    };
  });

  return (
    <section 
      className={styles.search} 
      aria-label="Search products" 
      role="dialog" 
      aria-modal="true" 
      aria-hidden="true"
      ref={panelRef}
    >
      <button 
        className={[aria_btn, styles.close].join(' ')}
        aria-label="Close sidebar" 
        onClick={(e)=> {handleClose()}}
      >
        <IconClose icon_classes={''} />
      </button>
      <div className={styles.head}>
        <div className={styles.inputwr}>
          <input type="search"
              name="q"
              placeholder="Search our store"
              className={styles.search_inpt}
              onChange={(e)=>{handleSearch()}}
              ref={inptRef}
           />
          <div className={styles.search_btn}>
            <IconSearch icon_classes={''} />
          </div>
          <div className={styles.search_gender} >
            <button 
              className={`${aria_btn} ${styles.gender_btn}`} 
              aria-label="Search all products" 
              aria-pressed={searchType === null ? 'true' : 'false'}
              onClick={(e)=>{setSearchType(null)}}
            >
              All
            </button>
            <button 
              className={`${aria_btn} ${styles.gender_btn}`} 
              aria-label="Search mens products" 
              aria-pressed={searchType === 'm' ? 'true' : 'false'}
              onClick={(e)=>{setSearchType('m')}}
            >
              Men's
            </button>
            <button 
              className={`${aria_btn} ${styles.gender_btn}`} 
              aria-label="Search womens products" 
              aria-pressed={searchType === 'w' ? 'true' : 'false'}
              onClick={(e)=>{setSearchType('w')}}
            >
              Women's
            </button>
          </div>
        </div>
      </div>
      <div className={styles.resultswr} style={{maxHeight: (prodResults.length > 0) ? '110vh' : 0}}>
        <div 
          className={styles.results} 
          ref={resultsRef}
        >
          {(prodResults.length > 0) && (
            <div className={styles.restitle}>
              <div className={styles.title}>Products</div>
              <div className={styles.count}>{prodResults.length} results found</div>
            </div>
          )}
          {prodResults.length > 0 && prodResults.map( (result, index) => {
            let image = result.media.length > 0 ? imgSize(result.media[0], 300) : false
            const is_gift = result.handle.includes('gift')
            const colors = result.pl_colors ? result.pl_colors : false

            let vpricMin = false
            let vpricMax = false

            if ( is_gift ) {
              vpricMin = 25
              vpricMax = 100
            }

            return (
              <Link 
                key={result.handle} 
                to={`/products/${result.handle}`} 
                className={styles.item} 
                result=""
                onClick={(e)=>{
                  handleClose();
                  gaClick(result, false, index)
                }}
              >
                {image && (
                  <div className={styles.image}>
                    <img src={image} alt={result.title} className={styles.img} loading="lazy" width="400" height="532" />
                  </div>
                )}
                <div className={styles.info}>
                  <div className={styles.item_title}>
                    {result.title}
                  </div>
                  <div className={styles.price}>
                    {is_gift ? (
                      <span>
                        ${vpricMin}–${vpricMax}
                      </span>
                    ) : result.percent_off ? (
                      <>
                        <span className={tstyles.price_sale}>
                          ({result.percent_off}% OFF)
                        </span>
                        <span className={tstyles.price_perc}>
                          ${result.first_product_price}
                        </span>
                        <span className={tstyles.price_full}>
                          ${result.first_full_price}
                        </span>
                      </>
                    ) : (
                      <>${result.first_product_price}</>
                    )}
                  </div>
                  {colors && (
                    <div className={styles.colors}>
                      {colors} Color{colors > 1 ? 's' : ''}
                    </div>
                  )}
                </div>
              </Link>
            )
          })}
        </div>
        <div className={styles.foot}>
          <button type="button" className={`${solid_btn} ${styles.btn}`} onClick={(e)=>{goto_page()}}> 
            See All Results
          </button>
        </div>
      </div>
      <div className={styles.blind} style={{maxHeight: (prodResults.length > 0) ? '0' : ''}}>
        <div className={styles.popular}>
          <div className={styles.title}>
            {content.popular_title}
          </div>
          <ol className={styles.popular_list}>
            {content.best_links.length > 0 && content.best_links.map( (link) => 
              <li key={link.title} className={styles.popular_item}>
                <Link 
                  to={link.url} 
                  aria-label={`Popular Search: ${link.title}`}
                  onClick={(e)=>{
                    handleClose();
                    gaClick(false,link.title)
                  }} 
                >
                  {link.title}
                </Link>
              </li>
            )}
            {content.popular_links.length > 0 && content.popular_links.map( (link) => 
              <li key={link.title} className={styles.popular_item}>
                <Link 
                  to={link.url} 
                  aria-label={`Popular Search: ${link.title}`}
                  onClick={(e)=>{
                    handleClose();
                    gaClick(false,link.title)
                  }} 
                >
                  {link.title}
                </Link>
              </li>
            )}
          </ol>
        </div>
      </div>
    </section>
  )
};

export default Search
