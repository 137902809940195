// extracted by mini-css-extract-plugin
export var color_name = "swatches-module--color_name--9d9e8";
export var colors_more = "swatches-module--colors_more--e6cf0";
export var cs = "swatches-module--cs--cbd56";
export var nswatch = "swatches-module--nswatch--6e6e6";
export var oos = "swatches-module--oos--11b30";
export var pdp_swatches = "swatches-module--pdp_swatches--a6690";
export var scolor = "swatches-module--scolor--d9786";
export var scurr = "swatches-module--scurr--b9f24";
export var swatch_type = "swatches-module--swatch_type--9b782";
export var swatches = "swatches-module--swatches--f9761";
export var swatches_info = "swatches-module--swatches_info--fba95";