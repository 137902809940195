import { useStaticQuery, graphql } from "gatsby"

export const useFooterListsQuery = () => {
  const { footerLists } = useStaticQuery(
    graphql`
      {
        footerLists: contentPage(type: {eq: "NewFooterList"}) {
          data
        }
      }
    `
  )
  return footerLists.data
}