import { getClientBrowserParameters } from '@shopify/hydrogen-react';
import { request } from 'graphql-request'


class QlReq {

  static async post( query, vars ) {
    const browserParams = {
      ...getClientBrowserParameters(),
    };

    const token = process.env.GATSBY_STOREFRONT_API_TOKEN
    const headers = {
      'X-Shopify-Storefront-Access-Token': token,
      'Content-Type': 'application/graphql',
      'Shopify-Storefront-Y': browserParams.uniqueToken,
      'Shopify-Storefront-S': browserParams.visitToken
    }
    const url = process.env.GATSBY_STOREFRONT_API_URL



    const req = await request(url, query, vars, headers)
    return req

    // _shopify_y = uniqueToken
    // _shopify_s = visitToken
    // header: Shopify-Storefront-Y - value: _shopify_y  cookie value
    // header: Shopify-Storefront-S - value: _shopify_s  cookie value
  }

}

export default QlReq