
const Geoloc = () => {

  const getGeo = async (noreq=false, saved=false) => {
    let newLoc = null
    if (noreq !== 'skipip') {
      newLoc = await getFenix()
    }
    
    let cft = {}
    if (noreq === 'skipip' || (!noreq && !newLoc)) newLoc = await getGoogle()
    cft = !newLoc?.country ? await getCFdata() : {}

    const cntry = !!saved?.usrCountry ? saved.usrCountry : newLoc?.country || cft?.loc || null
    const notUS = cntry !== 'US'

    let obj = {
      zip: newLoc?.zip || false, 
      country: newLoc?.country || cft?.loc || false, 
      ip: newLoc?.ip || cft?.ip || false,
      currStores: saved?.currStores || [],
      usrZip: saved?.usrZip || null,
      usrCountry: saved?.usrCountry || null,
      pdpStore: saved?.pdpStore || null,
      shipIntl: saved?.shipIntl || notUS,
      storeGend: saved?.storeGend || false,
    }

    return obj
  }

  const getFenix = async () => {
    return new Promise((resolve, reject) => { 
      fetch('https://ipapi.co/json/?key=Jaio0rg93RS6UPxzEXB19vvw4NKUb292e9pLeiDOjsfnSjyLc5', {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then(req => {
        if (req.status !== 200) resolve(false)
        return req.json()
      })
      .then(data => {
        resolve({zip: data.postal, country: data.country, ip: data.ip})
      })
    })
  }

  const getGoogle = async () => {
    const coords = await request()
    var gcoords = new window.google.maps.LatLng(coords.latitude, coords.longitude);
    return new Promise((resolve, reject) => { 
      new window.google.maps.Geocoder().geocode({'latLng': gcoords}, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
          let testZip = results[0].address_components.find( x => x.types.includes("postal_code"))
          const testCountry = results[0].address_components.find( x => x.types.includes("country"))
          resolve({zip: testZip.long_name, country: testCountry.short_name})
        } else {
          console.log("Geocoder failed due to: " + status);
          resolve({zip: false, country: false})
        }
      })
    })
  }

  const getCFdata = () => {
    return new Promise((resolve, reject) => {
      fetch('https://www.cloudflare.com/cdn-cgi/trace')
      .then((response) => response.text())
      .then((data) => {
        const cfdata = data.trim().split('\n').reduce((obj, pair) => {
          pair = pair.split('='); // Split the pair into key and value
          obj[pair[0]] = pair[1]; // Assign the value to the object
          return obj; // Return the object
        }, {})
        resolve(cfdata)
      })
    })
  }

  const mapObj = (zip) => {
    return new Promise((resolve, reject) => {
      new window.google.maps.Geocoder().geocode({address: zip}, (res, status) => {
        const has_zip = res && res.find(item => item.address_components.find( x => x.types.includes("postal_code")) )
        if (status === window.google.maps.GeocoderStatus.OK && has_zip) {
          resolve(has_zip)
        } else {
          resolve({})
        }
      })
    })
  }

  const request = () => {
    return new Promise((resolve, reject) => {
      if (!('geolocation' in navigator)) {
        resolve(false)
      }
      navigator.geolocation.getCurrentPosition(
        position => resolve(position.coords),
        error => resolve(false),
        {
          enableHighAccuracy: false,
          timeout: 5000,
          maximumAge: 0
        }
      )
    })
  }

  const doStoresSort = (items) => {
    return items.sort((a, b) => {
      if (a.distance === false && b.distance !== false) return 1;
      if (a.distance !== false && b.distance === false) return -1; 
      if (a.distance === false && b.distance === false) return 0;
      return a.distance - b.distance; 
    })
  }

  return { getGeo, mapObj, doStoresSort }
};

export default Geoloc;

