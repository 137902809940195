// extracted by mini-css-extract-plugin
export var afterpay = "cart-module--afterpay--f7ca3";
export var body = "cart-module--body--66fdc";
export var cart = "cart-module--cart--89305";
export var cart_qty = "cart-module--cart_qty--e9fe2";
export var change = "cart-module--change--b5e28";
export var changing = "cart-module--changing--98502";
export var close = "cart-module--close--304d0";
export var coll = "cart-module--coll--9e851";
export var collections = "cart-module--collections--a4300";
export var color = "cart-module--color--ef895";
export var copy = "cart-module--copy--fb6fe";
export var count = "cart-module--count--2d124";
export var delivery = "cart-module--delivery--0d227";
export var details = "cart-module--details--9e509";
export var empty = "cart-module--empty--87ec0";
export var error = "cart-module--error--f182a";
export var errorbg = "cart-module--errorbg--4a007";
export var final = "cart-module--final--58d2d";
export var foot = "cart-module--foot--892d5";
export var free_ship = "cart-module--free_ship--11c17";
export var gift = "cart-module--gift--25542";
export var gift_edit = "cart-module--gift_edit--d38b9";
export var gift_info = "cart-module--gift_info--80f47";
export var gift_msg = "cart-module--gift_msg--27593";
export var head = "cart-module--head--fbff6";
export var head_icon = "cart-module--head_icon--4c7e1";
export var hem = "cart-module--hem--16a44";
export var icon_cart = "cart-module--icon_cart--ec447";
export var icon_close = "cart-module--icon_close--33916";
export var icon_remove = "cart-module--icon_remove--fbb85";
export var image = "cart-module--image--1a4bc";
export var img = "cart-module--img--18411";
export var item = "cart-module--item--2e66f";
export var items = "cart-module--items--008c7";
export var label = "cart-module--label--f7741";
export var label_checkout = "cart-module--label_checkout--7138c";
export var message = "cart-module--message--4f00a";
export var notif = "cart-module--notif--23870";
export var notif_content = "cart-module--notif_content--21a1f";
export var off = "cart-module--off--30a0a";
export var plus = "cart-module--plus--f26eb";
export var price = "cart-module--price--a53a7";
export var prog_bar = "cart-module--prog_bar--6b5e1";
export var progress = "cart-module--progress--a6180";
export var qty_btn = "cart-module--qty_btn--f6c50";
export var qty_edit = "cart-module--qty_edit--383cc";
export var rec = "cart-module--rec--6c419";
export var rec_content = "cart-module--rec_content--5c035";
export var rec_image = "cart-module--rec_image--30177";
export var rec_more = "cart-module--rec_more--aa7f8";
export var rec_mores = "cart-module--rec_mores--6227d";
export var recs = "cart-module--recs--54f21";
export var recs_head = "cart-module--recs_head--ba992";
export var remove = "cart-module--remove--5ef0c";
export var returns = "cart-module--returns--b3e64";
export var size = "cart-module--size--1e875";
export var subtitle = "cart-module--subtitle--7cee8";
export var title = "cart-module--title--5eed6";
export var title_price = "cart-module--title_price--ff00a";
export var total = "cart-module--total--ad549";
export var upsell = "cart-module--upsell--8a28c";