/* eslint-disable jsx-a11y/role-supports-aria-props */

import * as React from "react"
import { Link } from "gatsby"
import { useProduct } from '~/contexts/ProductContext'


import * as styles from "~/styles/pdp/sizes_alts.module.scss"
import * as pdp_styles from "~/styles/pdp/pdp.module.scss"

// Add useEffect to grab current inventory / avail status on page load


const Alts = () => {
  const { product } = useProduct()
  const { is_gift, alt_cuts, handle } = product

  return (
    <div className="alt_cuts">
      <div className={[pdp_styles.info, pdp_styles.alts_info].join(' ')}>
        <div className={pdp_styles.label} id="ar_label-select_alt">
          {is_gift 
            ? 'Gift Type:'
            : `${alt_cuts.type}:`
          }
        </div>
      </div>

      <div 
        className={[styles.qsizes, styles.pdp_sizes].join(' ')}
        role="menu" 
        aria-labelledby="ar_label-select_alt"
      >
        {alt_cuts.opts.map( (opt, index) => {
          // const opt_pl = _.filter(PlG.pls, pl => _.find(pl.products, x => x.handle == opt.handle))
          let opt_avail = opt.availableForSale
          const is_pdp = handle === opt.handle

          return (
            <React.Fragment key={`alts${alt_cuts.type}${index}`}>
              {opt_avail ? (
                <Link 
                  className={`${styles.option} ${!opt_avail && styles.unavailable}`}
                  to={opt_avail ? `${handle !== opt.handle ? `/products/${opt.handle}` : ''}` : undefined}
                  aria-disabled={!opt_avail ? true : undefined}
                  aria-current={is_pdp ? 'true' : 'false'}
                  aria-label={is_pdp ? `${alt_cuts.type} ${opt.name} currently selected` : `Select ${alt_cuts.type} ${opt.label}`}
                  role="menuitem" 
                  tabIndex="-1"
                >
                  {opt.label}
                </Link>
              ) : (
                <div 
                  className={`${styles.option} ${!opt_avail && styles.unavailable}`}
                  aria-disabled={'true'}
                  aria-current={is_pdp ? 'true' : 'false'}
                  aria-label={is_pdp ? `${alt_cuts.type} ${opt.name} currently selected` : `Select ${alt_cuts.type} ${opt.label}`}
                  role="menuitem" 
                  tabIndex="-1"
                >
                  {opt.label}
                </div>
              )}
              
            </React.Fragment>
          )
        })}
      </div>
    </div>

  )
}

export default Alts
