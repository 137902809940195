// extracted by mini-css-extract-plugin
export var acc_btn = "footer-module--acc_btn--11062";
export var aria_btn = "footer-module--aria_btn--eebb5";
export var cont = "footer-module--cont--ea38c";
export var contact = "footer-module--contact--23394";
export var corp = "footer-module--corp--d2a1f";
export var corp_legal = "footer-module--corp_legal--6baea";
export var foot = "footer-module--foot--c0cec";
export var image = "footer-module--image--0cfa4";
export var img = "footer-module--img--65574";
export var legal = "footer-module--legal--af2bd";
export var legal_wr = "footer-module--legal_wr--44298";
export var links = "footer-module--links--043ff";
export var nd_ctaLink = "footer-module--nd_cta-link--13c71";
export var store_name = "footer-module--store_name--95889";
export var storewr = "footer-module--storewr--a7487";
export var title = "footer-module--title--2625c";